import React, {
    useMemo,
    Fragment,
    useEffect,
    useState,
    useCallback,
    useRef,
  } from "react";
  import { Container, Col } from "react-bootstrap";
  import dynamic from "next/dynamic";
  import ReadNews from "components/newsCard/readNews";
  import { useDispatch, useSelector } from "react-redux";
  import { getAdsBySize } from "redux/actions/ads.Actions";
  import { useRouter } from "next/router";
  import { subCategoriesItems } from "redux/actions/categoryActions";
  import { Swiper, SwiperSlide } from "swiper/react";
  import { debounce } from "debounce";
  import "swiper/css";
  import "swiper/css/navigation";
  import SwiperCore, { Navigation } from "swiper";
  import NotFound from "pages/404";
  import { getLanguage } from "localization/i8intl";
  import { FormattedMessage } from "react-intl";
  import InfiniteScroll from "react-infinite-scroll-component";
  import { baseUrl } from "api/constants";
  import Head from "next/head";
  import { NextSeo } from "next-seo";
  import { metaTags } from "../../constants/metaTags";
  import { MyLink } from "api/cleverTap";
  import { useWindowSize } from "api/commonConstants";
  import ElectionsWidget from "components/elections-2023/ElectionsWidget";
  // import Slider from "react-slick";
  import FAQComponent from "components/faq";
  import { MasterConfigContext } from "constants/MasterConfigContext";
  import { useContext } from "react";
  import Tally from "components/tally/Tally";
  import { stateData } from "constants/state";
  import {
    // apstagBidCallback,
    // createApsSlotsRosMobile,
    // createApsSlotsRosDesktop,
    requestBiddingFromPWT,
  } from "./../Ads/index";
  import Strip from "components/cricket/Strip";
  import WidgetHome from "components/cricket/WidgetHome";
  const DynamicBanner = dynamic(() =>
    import("components/homePage/dynamicBanner/bannerCarousel")
  );
  const NewsImageTextBanner1 = dynamic(
    () =>
      import("components/newsCard/newsImageTextBanner/newsImageTextBanner1.js"),
    { ssr: true }
  );

  const ReactJWPlayer = dynamic(() => import("react-jw-player"), { ssr: true });
  
  const Health = ({
    newsData,
    categoryName,
    subCategoryName,
    liveBlogData,
    seoData,
    electionTopicData = [],
    metaDetails = {},
    faqData,
    articlePageBanners,
    breadCrump,
    fromRoot,
    propData,
    pointsData,
    scoreData
  }) => {
    console.log("subCategoryNamebl", newsData);
  

    const [canScrollRight, setCanScrollRight] = useState(false);
  
    const STATIC_BREADCRUMP = [
      {
        displayText: "कौन जीता-कौन हारा",
        url: "/election/delhi-assembly-chunav/results",
        keyToShow: "Delhi_KaunJeeta",
      },
      {
        displayText: "प्रमुख उम्मीदवार",
        url: "/election/delhi-assembly-chunav/key-candidates",
        keyToShow: "DelhiKeyCandidate",
      },
      {
        displayText: "एग्जिट पोल",
        url: "/election/delhi-assembly-chunav/exit-poll",
        keyToShow: "DelhiExitPollPage",
      },
      {
        displayText: "विधान सभा रिजल्ट",
        url: "/election/delhi-assembly-chunav/party-wise-result",
        keyToShow: "DelhiResultPage",
      },
      {
        displayText: "शेड्यूल",
        url: "/election/delhi-assembly-chunav/schedule",
        keyToShow: "DelhiSchedulePage",
      },
      {
        displayText: "सामान्य प्रश्न",
        url: "/election/assembly-chunav/faq",
        keyToShow: "",
      },
    ];

    const masterConfig =
      useContext(MasterConfigContext)?.[
      propData?.masterConfigKey
        ? propData.masterConfigKey
        : "Delhi_vidhansabha_election"
      ];
      
      const masterConfigCT = useContext(MasterConfigContext);
      

  
    console.log("Masterconfig articlePageBanners ", articlePageBanners);
    const data = useSelector((data) => data?.CategoryReducer?.subCategoryItem);
    const [electionStuff, setElectionStuff] = useState({});
    const size = useWindowSize();
    const router = useRouter();
    useEffect(() => {
      if (data?.length > 0) {
        const x = data.filter((x) => x.slug === "elections-2023");
        if (x) {
          setElectionStuff(x);
        }
      }
    }, [data]);
    const padtaalStaticHeadings = [
      "हमारे बारे में",
      "पड़ताल का तरीका",
      "भूल सुधार",
      "कॉन्टेक्ट",
      "शिकायत",
    ];
    const padtaalStaticHeadingsEng = [
      "About Us",
      "Methodology",
      "Correction Policy",
      "Contact",
      "Complaints",
    ];
    const padtaalStaticHeadingsUrl = [
      "factcheck-about-us",
      "factcheck-methodology",
      "factcheck-correction-policy",
      "factcheck-contact-us",
      "factcheck/complaints",
    ];
    const { query } = router;
  
    const pageToOpen = router?.pathname.includes("/text")
      ? "text,liveblog"
      : router?.pathname.includes("/video")
        ? "video"
        : "video,text,liveblog";
 
  
  
    const mainUrl = ""
  
    const [posts, setPosts] = useState(newsData);
    const [hasMore, setHasMore] = useState(true);
    const [skipPerPage, setSkipPerPage] = useState(3);
    // const [electionTopicData, setElectionTopicData] = useState([]);
    const [firstScroll, setFirstScroll] = useState(false);
    const [showMetaTitleFromApi, setShowMetaTitleFromApi] = useState("");
    const [showMetaKeywordFromApi, setShowMetaKeywordFromApi] = useState("");
    const [showMetaDescriptionFromApi, setShowMetaDescriptionFromApi] =
      useState("");
  

  
    useEffect(() => {
      if (firstScroll) {
        // console.log("Inside here");
        setTimeout(() => {
          window._taboola = window._taboola || [];
          _taboola.push({
            mode: "alternating-thumbnails-a",
            container: "taboola-below-category-thumbnails",
            placement: "Below Category Thumbnails",
            target_type: "mix",
          });
          window._taboola = window._taboola || [];
          _taboola.push({ category: "auto" });
          !(function (e, f, u, i) {
            if (!document.getElementById(i)) {
              e.async = 1;
              e.src = u;
              e.id = i;
              f.parentNode.insertBefore(e, f);
            }
          })(
            document.createElement("script"),
            document.getElementsByTagName("script")[0],
            "//cdn.taboola.com/libtrc/indiatoday-thelallantop/loader.js",
            "tb_loader_script"
          );
          if (
            window.performance &&
            typeof window.performance.mark == "function"
          ) {
            window.performance.mark("tbl_ic");
          }
  
          (function () {
            /* CONFIGURATION START */
            var _sf_async_config = (window._sf_async_config =
              window._sf_async_config || {});
            _sf_async_config.uid = 60355;
            _sf_async_config.domain = "thelallantop.com";
            _sf_async_config.useCanonical = true;
            _sf_async_config.useCanonicalDomain = true;
            _sf_async_config.sections = `${router.pathname.includes("latest")
                ? "latest"
                : categoryKeyword || router.query?.categoryName
              }`;
            _sf_async_config.authors = "";
            /* CONFIGURATION END */
            function loadChartbeat() {
              var e = document.createElement("script");
              var n = document.getElementsByTagName("script")[0];
              e.type = "text/javascript";
              e.async = true;
              e.src = "//static.chartbeat.com/js/chartbeat.js";
              n.parentNode.insertBefore(e, n);
            }
            loadChartbeat();
          })();
        }, 10);
      }
    }, [firstScroll, newsData]);
    useEffect(() => {
      setPosts(newsData);
    }, [newsData]);
  
  
    useEffect(() => {
      if (mainUrl === "") {
        console.log("Run111 1", metaDetails);
        if ("metaTitle" in metaDetails) {
          console.log("Run111 2", metaDetails["metaTitle"]);
  
          setShowMetaTitleFromApi(metaDetails["metaTitle"]);
        } else if (
          category &&
          metaTags[category] &&
          Object.keys(metaTags[category]).length > 0
        ) {
          setShowMetaTitleFromApi(
            metaTags[category] &&
            metaTags[category === "latest" ? pageType : category][
              path[path.length - 1]
            ]?.metaTitle
          );
        } else {
          setShowMetaTitleFromApi("TheLallantop");
        }
        if ("metaDescription" in metaDetails) {
          setShowMetaDescriptionFromApi(metaDetails["metaDescription"]);
        } else if (
          category &&
          metaTags[category] &&
          Object.keys(metaTags[category]).length > 0
        ) {
          setShowMetaDescriptionFromApi(
            metaTags[category] &&
            metaTags[category === "latest" ? pageType : category][
              path[path.length - 1]
            ]?.metaDescription
          );
        } else {
          setShowMetaDescriptionFromApi(
            "TheLallantop provides Latest Hindi News on Politics, Business, Bollywood, Cricket, Education, Sports and more from India and around the world"
          );
        }
        if ("metaKeywords" in metaDetails) {
          setShowMetaKeywordFromApi(metaDetails["metaKeywords"]);
        } else {
          setShowMetaKeywordFromApi(
            `${metaTags[category] &&
            metaTags[category === "latest" ? pageType : category][
              path[path.length - 1]
            ]?.metaKeyword
            }`
          );
        }
      } else if (mainUrl === "text") {
        if ("metaTitleText" in metaDetails) {
          setShowMetaTitleFromApi(metaDetails["metaTitleText"]);
        } else if (
          category &&
          metaTags[category] &&
          Object.keys(metaTags[category]).length > 0
        ) {
          setShowMetaTitleFromApi(
            metaTags[category] &&
            metaTags[category === "latest" ? pageType : category][
              path[path.length - 1]
            ]?.metaTitle
          );
        } else {
          setShowMetaTitleFromApi("TheLallantop");
        }
        if ("metaDescriptionText" in metaDetails) {
          setShowMetaDescriptionFromApi(metaDetails["metaDescriptionText"]);
        } else if (
          category &&
          metaTags[category] &&
          Object.keys(metaTags[category]).length > 0
        ) {
          setShowMetaDescriptionFromApi(
            metaTags[category] &&
            metaTags[category === "latest" ? pageType : category][
              path[path.length - 1]
            ]?.metaDescription
          );
        } else {
          setShowMetaDescriptionFromApi(
            "TheLallantop provides Latest Hindi News on Politics, Business, Bollywood, Cricket, Education, Sports and more from India and around the world"
          );
        }
        if ("metaKeywordsText" in metaDetails) {
          setShowMetaKeywordFromApi(metaDetails["metaKeywordsText"]);
        } else {
          setShowMetaKeywordFromApi(
            `${metaTags[category] &&
            metaTags[category === "latest" ? pageType : category][
              path[path.length - 1]
            ]?.metaKeyword
            }`
          );
        }
      } else {
        if ("metaTitleVideo" in metaDetails) {
          setShowMetaTitleFromApi(metaDetails["metaTitleVideo"]);
        } else if (
          category &&
          metaTags[category] &&
          Object.keys(metaTags[category]).length > 0
        ) {
          setShowMetaTitleFromApi(
            metaTags[category] &&
            metaTags[category === "latest" ? pageType : category][
              path[path.length - 1]
            ]?.metaTitle
          );
        } else {
          setShowMetaTitleFromApi("TheLallantop");
        }
        if ("metaDescriptionVideo" in metaDetails) {
          setShowMetaDescriptionFromApi(metaDetails["metaDescriptionVideo"]);
        } else if (
          category &&
          metaTags[category] &&
          Object.keys(metaTags[category]).length > 0
        ) {
          setShowMetaDescriptionFromApi(
            metaTags[category] &&
            metaTags[category === "latest" ? pageType : category][
              path[path.length - 1]
            ]?.metaDescription
          );
        } else {
          setShowMetaDescriptionFromApi(
            "TheLallantop provides Latest Hindi News on Politics, Business, Bollywood, Cricket, Education, Sports and more from India and around the world"
          );
        }
        if ("metaKeywordsVideo" in metaDetails) {
          setShowMetaKeywordFromApi(metaDetails["metaKeywordsVideo"]);
        } else {
          setShowMetaKeywordFromApi(
            `${metaTags[category] &&
            metaTags[category === "latest" ? pageType : category][
              path[path.length - 1]
            ]?.metaKeyword
            }`
          );
        }
      }
    }, []);
  
    const getMorePost1 = debounce(async () => {
      if (router?.pathname.includes("/[categoryName]/[subCategoryName]")) {
        const res = await fetch(
          `${baseUrl}/web/postListBySubCategory/${query?.categorySlug || query?.categoryName
          }/${query?.subCategorySlug || query?.subCategoryName}?limit=9&skip=${isNaN(skipPerPage) ? "2" : skipPerPage
          }&type=${query.type == 'text' ? 'text,liveblog' : query.type == 'video' ? 'video' : pageToOpen}`,
          {
            headers: {
              accesstype: "web",
            },
          }
        );
        const newPosts = await res.json();
        setPosts((post) => [...post, ...newPosts?.result?.entities]);
        setSkipPerPage((skip) => skip + 1);
        if (!newPosts?.result?.entities.length) {
          setHasMore(false);
        }
      } else if (router?.pathname.includes("/[categoryName]")) {
  
  
  
        console.log(query.type, ' querytype');
  
  
        const res = await fetch(
          `${baseUrl}/web/postListByCategory/${query?.categorySlug || query?.categoryName
          }?limit=9&skip=${isNaN(skipPerPage) ? "2" : skipPerPage
          }&type=${query.type == 'text' ? 'text,liveblog' : query.type == 'video' ? 'video' : pageToOpen}`,
          {
            headers: {
              accesstype: "web",
            },
          }
        );
        const newPosts = await res.json();
        setPosts((post) => [...post, ...newPosts?.result?.entities]);
        setSkipPerPage((skip) => skip + 1);
        if (!newPosts?.result?.entities.length) {
          setHasMore(false);
        }
      } else if (router?.pathname.includes("/featured")) {
        const res = await fetch(
          `${baseUrl}/featured/rankFeatured?limit=9&skip=${isNaN(skipPerPage) ? "2" : skipPerPage
          }&type=${query.type == 'text' ? 'text,liveblog' : query.type == 'video' ? 'video' : pageToOpen}`,
          {
            headers: {
              accesstype: "web",
            },
          }
        );
        const newPosts = await res.json();
        setPosts((post) => [...post, ...newPosts?.result?.entities]);
        setSkipPerPage((skip) => skip + 1);
        if (!newPosts?.result?.entities.length) {
          setHasMore(false);
        }
      } else if (router?.pathname.includes("/latest")) {
        const res = await fetch(
          `${baseUrl}/web/trending?limit=9&skip=${isNaN(skipPerPage) ? "2" : skipPerPage
          }&type=${query.type == 'text' ? 'text,liveblog' : query.type == 'video' ? 'video' : pageToOpen}`,
          {
            headers: {
              accesstype: "web",
            },
          }
        );
  
        const newPosts = await res.json();
        setPosts((post) => [...post, ...newPosts?.result?.entities]);
        setSkipPerPage((skip) => skip + 1);
        if (!newPosts?.result?.entities.length) {
          setHasMore(false);
        }
      }
    }, 300);
    const [subCategory, setSubCategory] = useState("");
    const [subCategoryHindi, setSubCategoryHindi] = useState("");
  
    const [currentCategory, setCurrentCategory] = useState();
    const [categoryKeyword, setCategoryKeyword] = useState("");
    const [adCalled, setAdCalled] = useState(false);
  
    useEffect(() => {
      async function onFirstScroll() {
        if (!firstScroll) {
          setFirstScroll(true);
        }
      }
      function handleScroll() {
        onFirstScroll();
        window.removeEventListener("scroll", handleScroll);
      }
  
      window.addEventListener("scroll", handleScroll);
    }, [currentCategory]);
  
    const dispatch = useDispatch();
    const { subCategoryItem, category1 } = useSelector(
      ({ CategoryReducer }) => CategoryReducer
    );
    const categorizedNewsArray = useMemo(() => {
      var i,
        j,
        arrayChunk = [],
        chunk = 9;
      // console.log("categorizedNewsArrayPosts", posts);
      for (i = 0, j = posts?.length; i < j; i += chunk) {
        arrayChunk.push(posts?.slice(i, i + chunk));
      }
  
      return arrayChunk;
    }, [posts]);
    const [language, setLanguage] = useState("");
    useEffect(() => {
      setLanguage(getLanguage());
    }, []);
    useEffect(() => {
      if (query?.categoryName) {
        const filteredSubCategory =
          subCategoryItem &&
          subCategoryItem.length > 0 &&
          subCategoryItem?.filter(
            (subCatItem) => subCatItem?.slug === query?.subCategoryName
          )[0];
        setSubCategory(
          language === "hi"
            ? filteredSubCategory?.nameHn
            : filteredSubCategory?.name
        );
        setSubCategoryHindi(filteredSubCategory?.name);
      }
    }, [query?.subCategoryName, subCategoryItem, language, query?.categoryName]);
  
    useEffect(() => {
      if (query?.categoryName) {
        const filteredCategory = category1?.categories?.filter(
          (catItem) => catItem?.slug === query?.categoryName
        )[0];
        setFirstScroll(false);
        setCurrentCategory(filteredCategory);
        setCategoryKeyword(
          language === "hi" ? filteredCategory?.nameHn : filteredCategory?.name
        );
      }
    }, [query?.categoryName, category1, language]);
  
    const formSubcategoryQuery = useCallback(
      (_id) => {
        const { subCategoryId, ...restQuery } = router.query;
        return { ...restQuery, subCategoryId: subCategoryId ? _id : _id };
      },
      [router.query]
    );
    const { adPlacement300_250, adPlacement970_250 } = useSelector(
      ({ AdsReducer }) => AdsReducer
    );
  
    SwiperCore.use([Navigation]);
    const navprevRef = React.useRef(null);
    const navnextRef = React.useRef(null);
  
    useEffect(() => {
      dispatch(getAdsBySize());
    }, [dispatch]);
  
    useEffect(() => {
      dispatch(subCategoriesItems({ _id: posts[0]?.categoryId }));
    }, [dispatch, posts]);
  
    const smallElection = [1, 2, 3, 4, 5];
  
    let category = router.pathname.includes("latest")
      ? "latest"
      : router.pathname.includes("text")
        ? "text"
        : router.pathname.includes("video")
          ? "video"
          : router.query?.categoryName;
    let pageType = "";
  
    if (router.pathname.includes("latest")) {
      pageType = router.pathname.includes("text")
        ? "text"
        : router.pathname.includes("video")
          ? "video"
          : "latest";
    }
    let path = router.asPath.split("/");

 
    useEffect(() => {
      var showAds = true;
  
      if (!firstScroll) {
        showAds = false;
      }
      if (showAds && !adCalled) {
        setAdCalled(true);
        
        setTimeout(() => {
          window.googletag = window.googletag || { cmd: [] };
          
          if (window.screen.width > 600) {
            googletag.cmd.push(function () {
              
              googletag
                .defineSlot(
                  "/1007232/Lallantop_Desktop_ROS_MTF_1_728x90",
                  [728, 90],
                  "div-gpt-ad-1701945220410-1"
                )
                .addService(googletag.pubads());
              {
                subCategoryName !== "haryana-assembly-chunav" &&
                  googletag
                    .defineSlot(
                      "/1007232/Lallantop_Desktop_ROS_MTF_2_728x90",
                      [728, 90],
                      "div-gpt-ad-1701945220410-2"
                    )
                    .addService(googletag.pubads());
              }
  
              //  googletag.defineOutOfPageSlot('/1007232/Lallantop_Mobile_HP_Mweb_Interstitial',googletag.enums.OutOfPageFormat.INTERSTITIAL).addService(googletag.pubads());
              googletag.pubads().enableSingleRequest();
              googletag.pubads().setTargeting("Category", [`${categoryName}`]);
              
            //   googletag
            //     .pubads()
            //     .setTargeting(
            //       "Keywords",
            //       `${metaTags[categoryName][path[path.length - 1]]?.metaKeyword}`
            //     );
              googletag.pubads().enableSingleRequest();
              googletag.pubads().disableInitialLoad();
              googletag.enableServices();
              
              requestBiddingFromPWT();
            //   googletag.display('div-gpt-ad-1701945163318-0')
            //   googletag.display('div-gpt-ad-1701945163318-0')
            //   googletag.display('div-gpt-ad-1701945163318-0')
            });
          } else {
            googletag.cmd.push(function () {
             
              googletag
                .defineSlot(
                  "/1007232/Lallantop_Mobile_ROS_MTF_1_300x250",
                  [
                    [336, 280],
                    [300, 250],
                  ],
                  "div-gpt-ad-1701946028239-1"
                )
                .addService(googletag.pubads());
              {
                subCategoryName !== "haryana-assembly-chunav" &&
                  googletag
                    .defineSlot(
                      "/1007232/Lallantop_Mobile_ROS_MTF_2_300x250",
                      [
                        [336, 280],
                        [300, 250],
                      ],
                      "div-gpt-ad-1701946028239-2"
                    )
                    .addService(googletag.pubads());
              }
  
              googletag
                .defineSlot(
                  "/1007232/Lallantop_Mobile_ROS_Bottom_320x50",
                  [320, 50],
                  "div-gpt-ad-1701946324333-0"
                )
                .addService(googletag.pubads());
              googletag
                .defineOutOfPageSlot(
                  "/1007232/Lallantop_Mobile_ROS_Mweb_Interstitial",
                  googletag.enums.OutOfPageFormat.INTERSTITIAL
                )
                .addService(googletag.pubads());
              googletag.pubads().enableSingleRequest();
              googletag.pubads().disableInitialLoad();
              googletag.enableServices();
              requestBiddingFromPWT();
            });
          }

          
  
       
        }, 500);
      }
    }, [firstScroll]);


    useEffect(() => {

         window.googletag = window.googletag || { cmd: [] };
         if (window.screen.width > 600) {
             googletag.cmd.push(function () {
               googletag
                 .defineSlot(
                   "/1007232/Lallantop_Desktop_ROS_ATF_728x90",
                   [
                     [728, 90],
                     [970, 250],
                     [970, 90],
                   ],
                   "div-gpt-ad-1701945163318-0"
                 )
                 .addService(googletag.pubads())
                 googletag?.pubads()?.enableSingleRequest();
                 googletag?.pubads().disableInitialLoad();
                 googletag?.enableServices();
                 requestBiddingFromPWT();
                })
             }
             else{
                 googletag.cmd.push(function () {
                     googletag
                       .defineSlot(
                         "/1007232/Lallantop_Mobile_ROS_ATF_300x250",
                         [
                           [336, 280],
                           [300, 250],
                         ],
                         "div-gpt-ad-1701946028239-0"
                       )
                       .addService(googletag.pubads());
                
                 googletag?.pubads().enableSingleRequest();
                 googletag?.pubads().disableInitialLoad();
                 googletag?.enableServices();
                 requestBiddingFromPWT();
                })
             }
 
        
            
     },[])
  
    if (typeof window != "undefined") {
      const swiper = document.getElementsByClassName("swiper-button-lock")[0];
      let element = document.getElementsByClassName("swiper")[0]?.style;
      if (swiper) {
        if (element) element.paddingRight = 0;
      } else {
        if (element) element.paddingRight = "7rem";
      }
    }
 
    const oddnaariMobile = (newsData, indexVaue) => {
      return (
        <>
          <div className="outer-containers3">
            <div
              className="content-container3"
              style={{ width: "100% !important" }}
            >
              <div className="content">
                <div className="left-area-Col">
                  <div style={{}}>
                    {newsData?.slice(0, 1)?.map((postData, index) => (
                      <Col key={`${postData?._id}`}>
                        {postData?._id && (
                          <NewsImageTextBanner1
                            postData={postData}
                            hTag={indexVaue == 0 ? 2 : 3}
                          />
                        )}
                      </Col>
                    ))}
  
                    { indexVaue === 0 && size.width < 600 && (
                      <>
                        {articlePageBanners?.length > 0 && (
                          <div
                            className="checkMobileOrNot "
                            style={{ marginTop: "6px" }}
                          >
                            <DynamicBanner
                              list={articlePageBanners}
                              type={"msite"}
                              page={"category"}
                            />
                          </div>
                        )}
                        {masterConfig?.MoveAdsUp != true && <Container align="center">
                         
                          <div className="container-row py-2" align="center">
                            <p
                              style={{
                                fontSize: "11px",
                                color: "#b7b7b7",
                                textAlign: "center",
                                marginBottom: "0px",
                              }}
                            >
                              Advertisement
                            </p>
                            <div
                              id="div-gpt-ad-1701946028239-0"
                              style={{
                                minWidth: "300px",
                                minHeight: "260px",
                                display: "block !important",
                                alignContent: "center",
                                textAlign: "-webkit-center",
                              }}
                              className="ads-auto-resize"
                            >
                              <script
                                type="text/javascript"
                                dangerouslySetInnerHTML={{
                                  __html: `{googletag.cmd.push(function() { googletag.display('div-gpt-ad-1701946028239-0')})`,
                                }}
                              ></script>
                            </div>
                          </div>
                        </Container>}
                      </>
                    )}
                   
                  </div>
                  {newsData?.slice(1, 3)?.map((postData, index) => (
                    <Col key={`${postData?._id}`}>
                      <ReadNews
                        key={`index_${index}_${postData?._id}`}
                        postData={postData}
                        isBanner={false}
                      />
                    </Col>
                  ))}
                </div>
  
                {/* add widget here  */}
              {(subCategoryName == 'cricket')  && masterConfigCT['ChampionsTrophyWidget']['MainBanners'] && <div style = {{maxWidth : "400px"}}><WidgetHome newsData = {electionTopicData}    scoreData = {scoreData} pointsData={pointsData}  table = {pointsData.standings.stage1.groups[0]} size = {size}/></div>}
  
                <div className="left-area-Col">
                  <div style={{}}>
                    {newsData?.slice(3, 4)?.map((postData, index) => (
                      <Col key={`${postData?._id}`}>
                        {postData?._id && (
                          <NewsImageTextBanner1
                            postData={postData}
                            hTag={indexVaue == 0 ? 2 : 3}
                          />
                        )}
                      </Col>
                    ))}
                   
                  </div>
                  {newsData?.slice(4, 6)?.map((postData, index) => (
                    <Col key={`${postData?._id}`}>
                      <ReadNews
                        key={`index_${index}_${postData?._id}`}
                        postData={postData}
                        isBanner={false}
                      />
                    </Col>
                  ))}
                </div>
                <div className="right-area-Col">
                  <div style={{}}>
                    {newsData?.slice(6, 7)?.map((postData, index) => (
                      <Col key={`${postData?._id}`}>
                        {postData?._id && (
                          <NewsImageTextBanner1
                            postData={postData}
                            hTag={indexVaue == 0 ? 2 : 3}
                          />
                        )}
                      </Col>
                    ))}
                    
                  </div>
                  {newsData?.slice(7, 9)?.map((postData, index) => (
                    <Col key={`${postData?._id}`}>
                      <ReadNews
                        key={`index_${index}_${postData?._id}`}
                        postData={postData}
                        isBanner={false}
                      />
                    </Col>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </>
      );
    };
    const oddnaariDesktop = (newsData, indexVaue) => {
      var columnA = [];
      var columnB = [];
      var columnC = [];
      columnA.push(newsData[0], newsData[3], newsData[6]);
      columnB.push(newsData[1], newsData[4], newsData[7]);
      columnC.push(newsData[2], newsData[5], newsData[8]);
  
      return (
        <>
          <div className="outer-containers3">
            <div
              className="content-container3"
              style={{ width: "100% !important" }}
            >
              <div className="content">
                <div className="left-area-Col">
                  <div style={{}}>
                    {columnA?.slice(0, 1)?.map((postData, index) => (
                      <Col key={`${postData?._id}`}>
                        {postData?._id && (
                          <NewsImageTextBanner1
                            postData={postData}
                            hTag={indexVaue == 0 ? 2 : 3}
                          />
                        )}
                      </Col>
                    ))}
  
                    {/* <ReadNews postData={featuredPosts[0].post} isBanner={true} /> */}
                  </div>
                  {/* //here changes */}
                  {columnA?.slice(1, 3)?.map((postData, index) => (
                    <Col key={`${postData?._id}`}>
                      <ReadNews
                        key={`index_${index}_${postData?._id}`}
                        postData={postData}
                        isBanner={false}
                      />
                    </Col>
                  ))}
                </div>
                <div className="left-area-Col">
                  <div style={{}}>
                    {columnB?.slice(0, 1)?.map((postData, index) => (
                      <Col key={`${postData?._id}`}>
                        {postData?._id && (
                          <NewsImageTextBanner1
                            postData={postData}
                            hTag={indexVaue == 0 ? 2 : 3}
                          />
                        )}
                      </Col>
                    ))}
                    {/* <ReadNews postData={featuredPosts[0].post} isBanner={true} /> */}
                  </div>
                  {columnB?.slice(1, 3)?.map((postData, index) => (
                    <Col key={`${postData?._id}`}>
                      <ReadNews
                        key={`index_${index}_${postData?._id}`}
                        postData={postData}
                        isBanner={false}
                      />
                    </Col>
                  ))}
                </div>
                <div className="right-area-Col">
                  <div style={{}}>
                    {columnC?.slice(0, 1)?.map((postData, index) => (
                      <Col key={`${postData?._id}`}>
                        {postData?._id && (
                          <NewsImageTextBanner1
                            postData={postData}
                            hTag={indexVaue == 0 ? 2 : 3}
                          />
                        )}
                      </Col>
                    ))}
                    {/* <ReadNews postData={featuredPosts[0].post} isBanner={true} /> */}
                  </div>
                  {columnC?.slice(1, 3)?.map((postData, index) => (
                    <Col key={`${postData?._id}`}>
                      <ReadNews
                        key={`index_${index}_${postData?._id}`}
                        postData={postData}
                        isBanner={false}
                      />
                    </Col>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </>
      );
    };
    const sliderRef = useRef(null);
  
    const updateScrollButtons = () => {
      const slider = sliderRef.current;
      if (slider) {
        // setCanScrollLeft(slider.scrollLeft > 0);
        if (
          !canScrollRight &&
          slider.scrollLeft + slider.clientWidth < slider.scrollWidth
        ) {
          setCanScrollRight(true);
        }
      }
    };
  
    const scrollLeft = () => {
      sliderRef.current.scrollTo({
        left: sliderRef.current.scrollLeft + 150, // Adjust the value for how far to scroll left
        behavior: "smooth", // Enable smooth scrolling
      });
    };
  
    const scrollRight = () => {
      sliderRef.current.scrollTo({
        left: sliderRef.current.scrollLeft - 150, // Adjust the value for how far to scroll left
        behavior: "smooth", // Enable smooth scrolling
      });
    };
    useEffect(() => {
      const slider = sliderRef.current;
      if (slider && !canScrollRight) {
        updateScrollButtons(); // Check on mount
  
        // Attach event listeners
        slider.addEventListener("scroll", updateScrollButtons);
        window.addEventListener("resize", updateScrollButtons);
  
        // Cleanup
        return () => {
          slider.removeEventListener("scroll", updateScrollButtons);
          window.removeEventListener("resize", updateScrollButtons);
        };
      }
    }, [sliderRef?.current]);
    const subCatsHref = (subCatItem) => {
  
      // Fetch query params from the current URL
      const params = new URLSearchParams(window.location.search);
  
      let newStr = null
      if (params.toString()) {
  
        let url = `${`${mainUrl === "" ? "" : "/" + mainUrl + "/"}/${query?.categoryName
          }/${subCatItem?.slug}`}?${params.toString()}`;
        newStr = url.replace("//", "/");
      }
      else {
  
        let url = `${`${mainUrl === "" ? "" : "/" + mainUrl + "/"}/${query?.categoryName
          }/${subCatItem?.slug}`}`;
        newStr = url.replace("//", "/");
      }
  
  
      return newStr;
    };



  const breadcrumbSchemaUrlLevel2 = () => {
    let url = `${mainUrl}/${router.pathname.includes("latest") ? "latest" : router.query?.categoryName
      }`;
    let newStr = url.replace("//", "/");
    console.log(
      "newStr",
      process.env.NEXT_PUBLIC_AUTH_URL + "" + newStr.substring(1)
    );
    return process.env.NEXT_PUBLIC_AUTH_URL + "" + newStr.substring(1);
  };

    const SeoScripts = ({ showMetaTitleFromApi, showMetaDescriptionFromApi, showMetaKeywordFromApi, categoryName, seoData, newsData }) => {
        const router = useRouter();
        const [structuredData, setStructuredData] = useState(null);
      
      
      
        useEffect(() => {
          if (newsData?.length > 0) {
            const baseUrl = newsData[0]?.permalink?.split(".com/")[0] + ".com";
            const fullUrl = `${baseUrl}${router?.asPath}`;
      
            const jsonLdData = {
              webPage: {
                "@context": "http://schema.org/",
                "@type": "WebPage",
                name: showMetaTitleFromApi,
                description: showMetaDescriptionFromApi,
                keywords: showMetaKeywordFromApi,
                url: `https://www.thelallantop.com/${categoryName}`,
                publisher: {
                  "@type": "Organization",
                  name: "The Lallantop",
                  url: "https://www.thelallantop.com/",
                  logo: {
                    "@type": "ImageObject",
                    url: "https://www.thelallantop.com/assets/images/lallatop-logo-m.svg",
                    width: 600,
                    height: 60,
                  },
                },
              },
              breadcrumbList: {
                "@context": "https://schema.org",
                "@type": "BreadcrumbList",
                itemListElement: [
                  {
                    "@type": "ListItem",
                    position: 1,
                    item: {
                      "@id": process.env.NEXT_PUBLIC_AUTH_URL,
                      name: "Lallantop Hindi News",
                    },
                  },
                  {
                    "@type": "ListItem",
                    position: 2,
                    item: {
                      name: router.pathname.includes("latest") ? "लेटेस्ट" : newsData[0]?.categoryName,
                      "@id": breadcrumbSchemaUrlLevel2(),
                    },
                  },
                ],
              },
              itemList: {
                "@context": "https://schema.org",
                "@type": "ItemList",
                url: fullUrl,
                numberOfItems: "25",
                itemListOrder: "Ascending",
                mainEntityOfPage: {
                  "@type": "WebPage",
                  id: fullUrl,
                },
                itemListElement: seoData,
              },
            };
      
            setStructuredData(jsonLdData);
          }
        }, [showMetaTitleFromApi, showMetaDescriptionFromApi, showMetaKeywordFromApi, categoryName, seoData, newsData, router]);
      
        useEffect(() => {
          if (structuredData) {
            const addJsonLdScript = (id, jsonData) => {
              let script = document.getElementById(id);
              if (script) {
                script.remove(); // Remove existing script to avoid duplicates
              }
      
              script = document.createElement("script");
              script.id = id;
              script.type = "application/ld+json";
              script.textContent = JSON.stringify(jsonData);
              document.head.appendChild(script);
            };
      
            setTimeout(() => {
                addJsonLdScript("jsonld-webpage", structuredData.webPage);
                addJsonLdScript("jsonld-breadcrumbs", structuredData.breadcrumbList);
                addJsonLdScript("jsonld-itemlist", structuredData.itemList);
            },7000)
           
          }
        }, [structuredData]);
      
        return null; // No UI needed, just injecting scripts
      };
  
  
  
    return (
      <>
        <Head>
          <meta property="fb:pages" content="1453842511589254" />
        
        {/* hata diya he  */}
          {/* <script
            id="my-script-detail"
            type="application/ld+json"
            strategy="afterInteractive"
            dangerouslySetInnerHTML={{
              __html: JSON.stringify({
                "@context": "http://schema.org/",
                "@type": "WebPage",
                name: `${showMetaTitleFromApi}`,
                description: `${showMetaDescriptionFromApi}`,
                keywords: `${showMetaKeywordFromApi}`,
                url: `https://www.thelallantop.com/${categoryName}`,
                publisher: {
                  "@type": "Organization",
                  name: "The Lallantop",
                  url: "https://www.thelallantop.com/",
                  logo: {
                    "@type": "ImageObject",
                    url: "https://www.thelallantop.com/assets/images/lallatop-logo-m.svg",
                    width: 600,
                    height: 60,
                  },
                },
              }),
            }}
          ></script>        
            <script
              id="my-script-detail"
              type="application/ld+json"
              strategy="afterInteractive"
              dangerouslySetInnerHTML={{
                __html: JSON.stringify({
                  "@context": "https://schema.org",
                  "@type": "BreadcrumbList",
                  itemListElement: [
                    {
                      "@type": "ListItem",
                      position: 1,
                      item: {
                        // "@url": `${process.env.NEXT_PUBLIC_AUTH_URL}`,
                        "@id": `${process.env.NEXT_PUBLIC_AUTH_URL}`,
                        name: "Lallantop Hindi News",
                      },
                    },
                    {
                      "@type": "ListItem",
                      position: 2,
                      item: {
                        name: `${router.pathname.includes("latest")
                            ? "लेटेस्ट"
                            : newsData[0]?.categoryName
                          }`,
                        "@id": `${breadcrumbSchemaUrlLevel2()}`,
                      },
                    },
                  ],
                }),
              }}
            ></script>
          <script
            id="my-script2"
            type="application/ld+json"
            // strategy="afterInteractive"
            dangerouslySetInnerHTML={{
              __html: JSON.stringify({
                "@context": "https://schema.org",
                "@type": "ItemList",
                url: `${newsData[0]?.permalink?.split(".com/")[0] + ".com"}${router?.asPath
                  }`,
                numberOfItems: "25",
                itemListOrder: "Ascending",
                mainEntityOfPage: {
                  "@type": "WebPage",
                  id: `${newsData[0]?.permalink?.split(".com/")[0] + ".com"}${router?.asPath
                    }`,
                },
                itemListElement: seoData,
              }),
            }}
          ></script> */}
  
          <link
            rel="amphtml"
            href={
              path?.length > 3
                ? `${process.env.NEXT_PUBLIC_BASE_URL}/amp/${category}/${path[3]}`
                : `${process.env.NEXT_PUBLIC_BASE_URL}/amp/${category}`
            }
          />
          {console.log("pathpath", pageType)}
        </Head>
        {subCategoryItem?.length > 6 ? (
          <style>
            {` .swiper-wrapper{
              // justify-content: normal !important;
            }`}
          </style>
        ) : (
          <style>
            {` .swiper-wrapper{
              // justify-content: flex-end !important;
            }`}
          </style>
        )}
       
        <meta name="keywords" content={showMetaKeywordFromApi} />
        <NextSeo
          title={showMetaTitleFromApi}
          description={showMetaDescriptionFromApi}
          canonical={`${process.env.NEXT_PUBLIC_BASE_URL}${router?.asPath}`}
        />
  
    
  
        {router.asPath === "/election/lok-sabha-chunav-2024" &&
          size.width < 1100 && (
            <>
              <div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    paddingTop: "1rem",
                    gap: "8px",
                    cursor: "pointer",
                    paddingBottom: "1rem",
                    backgroundColor: "rgb(217, 54, 139)",
                    marginTop: "10px",
                  }}
                  onClick={() =>
                    window.open("/election/lok-sabha-chunav-2024", "_self")
                  }
                >
                  <img
                    src="/assets/images/chunav-name.png"
                    width="197.58px"
                    height="45.21px"
                    style={{ alignSelf: "center" }}
                  ></img>
                </div>
                <div
                  class="divCategoriesSchedule snipcss-eqdpv"
                  style={{
                    margin: "-1px 0px",
                    backgroundColor: "rgb(217, 54, 139)",
                    paddingLeft: "15px",
                    paddingBottom: "1rem",
                  }}
                >
                  <a href="/election/lok-sabha-chunav-2024/results">
                    <span>कौन जीता-कौन हारा</span>
                  </a>{" "}
                  <a href="/election/lok-sabha-chunav-2024/key-candidates">
                    <span>प्रमुख उम्मीदवार</span>
                  </a>
                  {/* {publicRuntimeConfig?.electionResultToggle && ( */}
                  <a href="/election/lok-sabha-chunav-2024/party-wise-result">
                    <span>लोक सभा रिजल्ट</span>
                  </a>
                  {/* )} */}
                  {/* {publicRuntimeConfig?.keyCandidate && ( */}
                  {/* )} */}
                  {/* {publicRuntimeConfig?.vidhansabhaResultToggle && ( */}
                  <a href="/election/vidhan-sabha-chunav-2024/party-wise-result">
                    <span>विधान सभा रिजल्ट</span>
                  </a>
                  {/* )} */}
                  {/* {publicRuntimeConfig?.electionExitPollToggle === true && (
                    <a href="/election/lok-sabha-chunav-2024/exit-poll">
                      <span>एग्जिट पोल</span>
                    </a>
                  )} */}
                  <a href="/election/lok-sabha-chunav-2024/schedules">
                    <span>शेड्यूल</span>
                  </a>{" "}
                </div>
              </div>
             
            </>
          )}
  
        {size.width < 600 && (
          <div
            style={{
              position: "relative",
              display: "flex",
              justifyContent: "center",
              width: "100%",
            }}
          >
            {router.asPath.includes("election") &&
              !router.asPath.includes("lok-sabha") && (
                <div style={{ position: "relative", width: "100%" }}>
                  <img
                    src={
                      electionStuff[0]?.creativeBanner ||
                      "/assets/images/election-background-mobile.png"
                    }
                    style={{
                      position: "relative",
                      width: "100%",
                      height: "auto",
                    }}
                  ></img>
                  <img
                    src="/assets/images/election-left-mobile.png"
                    width=""
                    height="70px"
                    style={{ position: "absolute", bottom: "10px", left: "10px" }}
                  ></img>
                  <img
                    src={
                      router.asPath.includes("haryana-assembly")
                        ? "/assets/images/right-election-top-2.png"
                        : router.asPath.includes("jammu-kashmir-assembly")
                          ? "/assets/images/jammu-kashmir.png"
                          : "/assets/images/indian-map.png"
                    }
                    width=""
                    height="90px"
                    style={{ position: "absolute", bottom: "0", right: "0px" }}
                  ></img>
                </div>
              )}
          </div>
        )}
  
        {/* Mobile Vidhansabha chunav Strip for all and haryana */}
        {size.width < 1100 && fromRoot && (
          <>
            <div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  paddingTop: "1rem",
                  gap: "8px",
                  // cursor: "pointer",
                  paddingBottom: "1rem",
                  backgroundColor: "white",
                  marginTop: "10px",
                }}
              // onClick={() =>
              //   window.open("/election/lok-sabha-chunav-2024", "_self")
              // }
              >
                <img
                  src="/assets/images/assembly-chunav.png"
                  width="240px"
                  height="auto"
                  style={{ alignSelf: "center" }}
                ></img>
              </div>
              <div
                class="divCategoriesSchedule2 snipcss-eqdpv"
                style={{
                  margin: "-1px 0px",
                  backgroundColor: "white",
                  paddingLeft: "15px",
                  paddingBottom: "1rem",
                  justifyContent:
                    router.asPath.includes("jharkhand-") ||
                      router.asPath.includes("maharashtra-")
                      ? "center"
                      : "space-around",
                }}
              >
                {breadCrump?.length > 0
                  ? breadCrump.map((item, index) => {
                    return masterConfig[item.keyToShow] != false ? (
                      <a key={index} href={item.url}>
                        <span>{item.displayText}</span>
                      </a>
                    ) : null;
                  })
                  : STATIC_BREADCRUMP.map((item, index) => {
                    return masterConfig[item.keyToShow] != false ? (
                      <a key={index} href={item.url}>
                        <span>{item.displayText}</span>
                      </a>
                    ) : null;
                  })}
               
              </div>
            </div>
          </>
        )}
  
        {/* Mobile Vidhansabha chunav strip for jammu */}
        {false &&
          router.asPath.includes("jammu-kashmir-") &&
          size.width < 1100 && (
            <>
              <div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    paddingTop: "1rem",
                    gap: "8px",
                    // cursor: "pointer",
                    paddingBottom: "1rem",
                    backgroundColor: "white",
                    marginTop: "10px",
                  }}
                // onClick={() =>
                //   window.open("/election/lok-sabha-chunav-2024", "_self")
                // }
                >
                  <img
                    src="/assets/images/assembly-chunav.png"
                    width="240px"
                    height="auto"
                    style={{ alignSelf: "center" }}
                  ></img>
                </div>
                <div
                  class="divCategoriesSchedule2 snipcss-eqdpv"
                  style={{
                    margin: "-1px 0px",
                    backgroundColor: "white",
                    paddingLeft: "15px",
                    paddingBottom: "1rem",
                    justifyContent: "space-around",
                  }}
                >
                  {masterConfig?.JK_KaunJeeta && (
                    <a href={`/election/jammu-kashmir-assembly-chunav/results`}>
                      <span>कौन जीता-कौन हारा</span>
                    </a>
                  )}
                  {masterConfig?.JKkeyCandidate && (
                    <a
                      href={`${router.asPath.includes("jammu-kashmir")
                          ? "/election/jammu-kashmir-assembly-chunav/key-candidates"
                          : "/election/haryana-assembly-chunav/key-candidates"
                        }`}
                    >
                      <span>प्रमुख उम्मीदवार</span>
                    </a>
                  )}
                  {masterConfig?.JKExitPollPage && (
                    <a href={`/election/jammu-kashmir-assembly-chunav/exit-poll`}>
                      <span>एग्जिट पोल</span>
                    </a>
                  )}
                  {masterConfig?.JKresultpage && (
                    <a
                      href={`/election/jammu-kashmir-assembly-chunav/party-wise-result`}
                    >
                      <span>विधान सभा रिजल्ट</span>
                    </a>
                  )}
                  {/* )} */}
                  <a
                    href={`${router.asPath.includes("jammu-kashmir")
                        ? "/election/jammu-kashmir-assembly-chunav/schedule"
                        : "/election/haryana-assembly-chunav/schedule"
                      }`}
                  >
                    <span>शेड्यूल</span>
                  </a>{" "}
                  <a href={"/election/assembly-chunav/faq"}>
                    <span>सामान्य प्रश्न</span>
                  </a>{" "}
                </div>
              </div>
            </>
          )}
  
        {/* Banner with Ad desktop */}
        {size.width >= 600 && (
          <div
            style={{
              position: "relative",
              display: "flex",
              justifyContent: "center",
            }}
          >
            {router.asPath.includes("election") &&
              !router.asPath.includes("lok-sabha") && (
                <div style={{ position: "relative" }}>
                  <img
                    src={
                      electionStuff[0]?.creativeBanner ||
                      "/assets/images/election-background-3.png"
                    }
                    style={{
                      position: "relative",
                      width: "100%",
                      height: "298px",
                    }}
                  ></img>
                  <img
                    src="/assets/images/left-election-top-2.png"
                    width=""
                    height="170px"
                    style={{ position: "absolute", bottom: "10px", left: "10px" }}
                  ></img>
                  <img
                    src={
                      router.asPath.includes("haryana-assembly")
                        ? "/assets/images/right-election-top-2.png"
                        : router.asPath.includes("jammu-kashmir-assembly")
                          ? "/assets/images/jammu-kashmir.png"
                          : "/assets/images/indian-map.png"
                    }
                    width=""
                    height="250px"
                    style={{ position: "absolute", bottom: "0", right: "0px" }}
                  ></img>
                </div>
              )}
            <Container
              align="center"
              style={{
                position:
                  router.asPath.includes("/election") &&
                    !router.asPath.includes("lok-sabha")
                    ? "absolute"
                    : "relative",
                top: router.asPath.includes("/election") ? "22px" : "0px",
              }}
            >
             
  
              <div className="container-row py-2" align="center">
                <p
                  style={{
                    fontSize: "11px",
                    color: "#b7b7b7",
                    textAlign: "center",
                    marginBottom: "0px",
                  }}
                >
                  Advertisement
                </p>
                <div
                  id="div-gpt-ad-1701945163318-0"
                  style={{
                    minWidth: "300px",
                    minHeight: "100px",
                    display: "block !important",
                    alignContent: "center",
                    textAlign: "-webkit-center",
                  }}
                  className="ads-auto-resize"
                >
                    {/* hata di he  */}
                  <script
                    type="text/javascript"
                    dangerouslySetInnerHTML={{
                      __html: `{googletag.cmd.push(function() { googletag.display('div-gpt-ad-1701945163318-0')})`,
                    }}
                  ></script>
                </div>
              </div>
            </Container>
          </div>
        )}
  
        {/* for loksaabha page */}
        {router.asPath === "/election/lok-sabha-chunav-2024" &&
          size.width >= 1100 && (
            <>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  backgroundColor: "#D9368B",
                  width: "85%",
                  margin: "auto",
                  gap: "68px",
                  height: "80px",
                  marginTop: "15px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    // paddingTop: "1rem",
                    gap: "8px",
                    cursor: "pointer",
                    backgroundColor: "rgb(217, 54, 139)",
                    paddingLeft: "20px",
                  }}
                  onClick={() =>
                    window.open("/election/lok-sabha-chunav-2024", "_self")
                  }
                >
                
                  <img
                    src="/assets/images/chunav-name.png"
                    width="197.56px"
                    height="45.21px"
                    style={{ alignSelf: "center" }}
                  ></img>
                </div>
                <div
                  class="divCategoriesSchedule snipcss-eqdpv"
                  style={{
                    margin: "0px 0px",
                    backgroundColor: "rgb(217, 54, 139)",
                    paddingLeft: "15px",
                    alignContent: "center",
                  }}
                >
                  <a href="/election/lok-sabha-chunav-2024/results">
                    <span>कौन जीता-कौन हारा</span>
                  </a>{" "}
                  <a href="/election/lok-sabha-chunav-2024/key-candidates">
                    <span>प्रमुख उम्मीदवार</span>
                  </a>
                  {/* {publicRuntimeConfig?.electionResultToggle && ( */}
                  <a href="/election/lok-sabha-chunav-2024/party-wise-result">
                    <span>लोक सभा रिजल्ट</span>
                  </a>
                  {/* )} */}
                  {/* {publicRuntimeConfig?.keyCandidate && ( */}
                  {/* )} */}
                  {/* {publicRuntimeConfig?.vidhansabhaResultToggle && ( */}
                  <a href="/election/vidhan-sabha-chunav-2024/party-wise-result">
                    <span>विधान सभा रिजल्ट</span>
                  </a>
                  {/* )} */}
                  {/* {publicRuntimeConfig?.electionExitPollToggle === true && ( */}
                  <a href="/election/lok-sabha-chunav-2024/exit-poll">
                    <span>एग्जिट पोल</span>
                  </a>
                  {/* )} */}
                  <a href="/election/lok-sabha-chunav-2024/schedules">
                    <span>शेड्यूल</span>
                  </a>{" "}
                </div>
              </div>
            </>
          )}
  
     
        {
          // (
          //   router.asPath.includes("-assembly")) &&
          size.width >= 1100 &&
          fromRoot &&
          !router.asPath.includes("lok-sabha-chunav-2024") && (
            <>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  backgroundColor: "white",
                  width: "max-content",
                  margin: "auto",
                  gap: "68px",
                  height: "80px",
                  marginTop: "15px",
                  borderRadius: "12px",
                  padding: "0px 30px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    // paddingTop: "1rem",
                    gap: "8px",
                    // cursor: "pointer",
                    backgroundColor: "white",
                    paddingLeft: "0px",
                  }}
                
                >
                  
                  <img
                    src="/assets/images/assembly-chunav.png"
                    width="408.56px"
                    height="58.21px"
                    style={{ alignSelf: "center" }}
                  ></img>
                </div>
                <div
                  class="divCategoriesSchedule2 snipcss-eqdpv"
                  style={{
                    margin: "0px 0px",
                    // backgroundColor: "rgb(217, 54, 139)",
                    paddingLeft: "15px",
                    alignContent: "center",
                  }}
                >
                  {breadCrump?.length > 0
                    ? breadCrump.map((item, index) => {
                      return masterConfig[item.keyToShow] != false ? (
                        <a key={index} href={item.url}>
                          <span>{item.displayText}</span>
                        </a>
                      ) : null;
                    })
                    : STATIC_BREADCRUMP.map((item, index) => {
                      return masterConfig[item.keyToShow] != false ? (
                        <a key={index} href={item.url}>
                          <span>{item.displayText}</span>
                        </a>
                      ) : null;
                    })}
               
                </div>
              </div>
            </>
          )
        }
  
        {/* Desktop strip  For jammu category */}
        {false &&
          size.width >= 1100 &&
          router.asPath.includes("jammu-kashmir-") && (
            <>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  backgroundColor: "white",
                  width: "max-content",
                  margin: "auto",
                  gap: "68px",
                  height: "80px",
                  marginTop: "15px",
                  borderRadius: "12px",
                  padding: "0px 30px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    // paddingTop: "1rem",
                    gap: "8px",
                    // cursor: "pointer",
                    backgroundColor: "white",
                    paddingLeft: "0px",
                  }}
               
                >
                 
                  <img
                    src="/assets/images/assembly-chunav.png"
                    width="408.56px"
                    height="58.21px"
                    style={{ alignSelf: "center" }}
                  ></img>
                </div>
                <div
                  class="divCategoriesSchedule2 snipcss-eqdpv"
                  style={{
                    margin: "0px 0px",
                    // backgroundColor: "rgb(217, 54, 139)",
                    paddingLeft: "15px",
                    alignContent: "center",
                  }}
                >
                  {masterConfig?.JK_KaunJeeta && (
                    <a href={`/election/jammu-kashmir-assembly-chunav/results`}>
                      <span>कौन जीता-कौन हारा</span>
                    </a>
                  )}
                  {masterConfig?.JKkeyCandidate && (
                    <a
                  
                      href={`${router.asPath.includes("jammu-kashmir")
                          ? "/election/jammu-kashmir-assembly-chunav/key-candidates"
                          : "/election/haryana-assembly-chunav/key-candidates"
                        }`}
                    >
                      <span>प्रमुख उम्मीदवार</span>
                    </a>
                  )}
                  {masterConfig?.JKExitPollPage && (
                    <a href={`/election/jammu-kashmir-assembly-chunav/exit-poll`}>
                      <span>एग्जिट पोल</span>
                    </a>
                  )}
                  {masterConfig?.JKresultpage && (
                    <a
                      href={`/election/jammu-kashmir-assembly-chunav/party-wise-result`}
                    >
                      <span>विधान सभा रिजल्ट</span>
                    </a>
                  )}
                  {/* )} */}
                  <a
                    href={`${router.asPath.includes("jammu-kashmir")
                        ? "/election/jammu-kashmir-assembly-chunav/schedule"
                        : "/election/haryana-assembly-chunav/schedule"
                      }`}
                  >
                    <span>शेड्यूल</span>
                  </a>{" "}
                  <a href={"/election/assembly-chunav/faq"}>
                    <span>सामान्य प्रश्न</span>
                  </a>{" "}
                </div>
              </div>
            </>
          )}
  
        {category == "election" && <ElectionsWidget />}
        <br></br>
      
        {category === "election" && (
          <>
         {masterConfig?.MoveAdsUp == true && size.width < 768 &&  <Container align="center">
             
              <div className="container-row py-2" align="center">
                <p
                  style={{
                    fontSize: "11px",
                    color: "#b7b7b7",
                    textAlign: "center",
                    marginBottom: "0px",
                  }}
                >
                  Advertisement
                </p>
                <div
                  id="div-gpt-ad-1701946028239-0"
                  style={{
                    minWidth: "300px",
                    minHeight: "260px",
                    display: "block !important",
                    alignContent: "center",
                    textAlign: "-webkit-center",
                  }}
                  className="ads-auto-resize"
                >
                  <script
                    type="text/javascript"
                    dangerouslySetInnerHTML={{
                      __html: `{googletag.cmd.push(function() { googletag.display('div-gpt-ad-1701946028239-0')})`,
                    }}
                  ></script>
                </div>
              </div>
            </Container>}
          <Tally
            stateName={subCategoryName && stateData[subCategoryName]?.tallyState}
            masterConfig={masterConfig}
          />
          </>
        )}
  
  {(subCategoryName == 'cricket')  && <Strip showActive = {'all'}  size={size} list={STATIC_BREADCRUMP} />}
  
        <br></br>
        {posts?.length > 0 && (
          <InfiniteScroll
            dataLength={posts?.length}
            next={getMorePost1}
            hasMore={hasMore}
            loader={<div className="lds-dual-ring"></div>}
            scrollThreshold={0.4}
          >
            {categorizedNewsArray?.map((categorizedNews, index) => {
              return (
                <div className="content-wrapper" key={index} id={index}>
                  {!!newsData?.length ? (
                    <Container>
                      <div className="container-row">
                        <div className="header-category-page">
                          {index === 0 && (
                            <>
                              <ul className="breadcrumb assembly-breadcrumb">
                                <li>
                                  <a href="/" passHref>
                                    <a aria-label="Lallantop Home">
                                      <i className="icon-cat-home"></i>
                                      <span>Home</span>
                                    </a>
                                  </a>
  
                                </li>
                              {
                                router.pathname.includes("latest")
                &&
                <li>
                  <span>Latest</span>
                </li>
                              }
                                {router.query?.categoryName && (
                                  <li>
                                    {router.query?.subCategoryName ? (
                                      <a
                                        href={`/${mainUrl === "" ? "" : mainUrl + "/"
                                          }${router.query?.categoryName}`}
                                        passHref
                                      >
                                        <a
                                          aria-label={`${router.query?.categoryName} Category Name`}
                                        >
                                          <span>{newsData[0]?.categoryName}</span>
                                        </a>
                                      </a>
                                    ) : (
                                      <span> {newsData[0]?.categoryName}</span>
                                    )}
                                  </li>
                                )}
  
                                {(router?.asPath === "/latest" ||
                                  router?.asPath === "/video/latest" ||
                                  router?.asPath === "/text/latest") && (
                                    <li>
                                      <span>{"Latest"}</span>
                                    </li>
                                  )}
  
                                {query?.categoryName &&
                                  query?.subCategoryName && (
                                    <li>
                                      <span> {subCategoryHindi}</span>
                                    </li>
                                  )}
                              </ul>
                            </>
                          )}
  
                          {index === 0 &&
                            router.query?.categoryName === "factcheck" && (
                              <ul className="breadcrumb" style={{ width: `${language === "hi" ? "500px" : "700px"}` }}>
                                {padtaalStaticHeadings.map((item, index) => (
                                  <li key={item}>
                                    {/* <a
                                    href=`/${padtaalStaticHeadingsUrl[index]}`
                                  > */}
                                    <a
                                      href={`${`/${padtaalStaticHeadingsUrl[index]}`}`}
                                    >
                                      <a aria-label="Lallantop Home">
                                        <span>
                                          <FormattedMessage
                                            id={
                                              language === "hi"
                                                ? item
                                                : padtaalStaticHeadingsEng[index]
                                            }
                                            defaultMessage={
                                              language === "hi"
                                                ? item
                                                : padtaalStaticHeadingsEng[index]
                                            }
                                          />
                                        </span>
                                      </a>
                                    </a>
                                  </li>
                                ))}
                              </ul>
                            )}
                        </div>
                        {index === 0 &&
                          size.width >= 600 &&
                          router.asPath.includes("election") &&
                          electionStuff[0]?.editorialBanner && (
                            <div>
                              <img
                                width="100%"
                                height="300px"
                                src={electionStuff[0]?.editorialBanner}
                              ></img>
                            </div>
                          )}
  
                        <Fragment key={`${index}_index`}>
                          <div className="news-card-box">
                            <div className="news-card-body">
                              {index === 0 &&
                                (categoryName !== "factcheck" ||
                                  (categoryName === "factcheck" &&
                                    size.width <= 499) ?
                                  (
                                    <div
                                      className={`cat-head-sec ${size.width <= 500
                                          ? "latest-cat-mob"
                                          : categoryName === "factcheck"
                                            ? "latest-cat-dtop"
                                            : ""
                                        }`}
                                    >
                                      <h1
                                        className="font-22-d title large px-3 px-md-0 mt-3 mt-md-0 "
                                        style={{ fontSize: "22px !important" }}
                                      >
                                        {router?.pathname.includes("/featured") ? (
                                          <FormattedMessage
                                            id="featured"
                                            defaultMessage="फीचर्ड"
                                          />
                                        ) : router?.pathname.includes("/latest") ? (
                                          <div>
                                            {language === "hi"
                                              ? "लेटेस्ट"
                                              : "Latest"}
                                          </div>
                                        ) : (
                                          categoryKeyword
                                        )}
                                      </h1>
  
                                      {!!subCategoryItem?.length &&
                                        !router?.pathname.includes("/featured") &&
                                        !router?.pathname.includes("/latest") &&
                                        size.width >= 768 && (
                                          <>
                                            <div
                                              style={{
                                                display: "flex",
                                                gap: "5px",
                                              }}
                                              className="bg-inf"
                                            >
                                              <div
                                                ref={sliderRef}
                                                style={{
                                                  display: "flex",
                                                  gap: "3px",
                                                  maxWidth: "500px",
                                                  overflowX: "hidden",
                                                  paddingRight: "20px",
                                                }}
                                                className="bg-succes"
                                              >
                                                {subCategoryItem
                                                  ?.filter(
                                                    (subCatItem, index) =>
                                                      categoryName !== "election" ||
                                                      index < 5
                                                  )
                                                  .map(
                                                    (subCatItem) =>
                                                      subCatItem?.slug !=
                                                      "naya-taaza" && (
                                                        <div
                                                          className="test-sub"
                                                          key={subCatItem?._id}
                                                        >
                                                          <a
                                                            className={
                                                              subCatItem?.slug ===
                                                                query?.subCategoryName
                                                                ? "active"
                                                                : "inactive"
                                                            }
                                                            href={subCatsHref(
                                                              subCatItem
                                                            )}
                                                            passHref
                                                          >
                                                            <MyLink>
                                                              <span>
                                                                {language === "hi"
                                                                  ? subCatItem?.nameHn
                                                                  : subCatItem?.name}{" "}
                                                              </span>
                                                            </MyLink>
                                                          </a>
                                                        </div>
                                                      )
                                                  )}
                                              </div>
  
                                              {canScrollRight && (
                                                <div className="slider-nav bg-dange">
                                                  <div
                                                    onClick={scrollRight}
                                                    className="prev"
                                                  >
                                                    <div className="swiper-circle">
                                                      <i className="icon-arrow-left swiper-icon-arrow"></i>
                                                    </div>
                                                  </div>
  
                                                  <div
                                                    onClick={scrollLeft}
                                                    className="next"
                                                  >
                                                    <div className="swiper-circle">
                                                      <i className="icon-arrow-right swiper-icon-arrow"></i>
                                                    </div>
                                                  </div>
                                                </div>
                                              )}
                                            </div>
                                          </>
                                        )}
  
                                      {router?.asPath !== "/latest" &&
                                        router?.asPath !== "/text/latest" &&
                                        router?.asPath !== "/video/latest" &&
                                        size.width < 768 && (
                                          <>
                                            <div
                                              style={{
                                                display: "flex",
                                                width: "95vw",
                                              }}
                                            >
                                              <div
                                                ref={sliderRef}
                                                style={{
                                                  display: "flex",
                                                  gap: "3px",
                                                  width: "100%",
                                                  overflowX: "scroll",
                                                  marginLeft: "-3%",
                                                }}
                                                className="mobile-subcat"
                                              >
                                                {subCategoryItem
                                                  ?.filter(
                                                    (subCatItem, index) =>
                                                      categoryName !== "election" ||
                                                      index < 5
                                                  )
                                                  .map(
                                                    (subCatItem) =>
                                                      subCatItem?.slug !=
                                                      "naya-taaza" && (
                                                        <div
                                                          className="test-sub"
                                                          key={subCatItem?._id}
                                                        >
                                                          <a
                                                            href={subCatsHref(
                                                              subCatItem
                                                            )}
                                                            passHref
                                                          >
                                                            <MyLink
                                                              className={
                                                                subCatItem?.slug ===
                                                                  query?.subCategoryName
                                                                  ? "active"
                                                                  : "inactive"
                                                              }
                                                            >
                                                              <span>
                                                                {language === "hi"
                                                                  ? subCatItem?.nameHn
                                                                  : subCatItem?.name}{" "}
                                                              </span>
                                                            </MyLink>
                                                          </a>
                                                        </div>
                                                      )
                                                  )}
                                              </div>
                                              {router?.asPath.includes(
                                                "/factcheck"
                                              ) && (
                                                  <div
                                                    className="right-side"
                                                  // style={{ marginTop: "20px" }}
                                                  >
                                                    {" "}
                                                    <a href="https://ifcncodeofprinciples.poynter.org/profile/tv-today-network-limited">
                                                      <a>
                                                        {" "}
                                                        <img
                                                          src="https://static.thelallantop.com/images/post/1713786729251_166a2699-7e1f-a8b0-8dff-d8eaf25ca394.webp"
                                                          // height="75px"
                                                          className="fact-check-logo"
                                                        />
                                                      </a>
                                                    </a>
                                                  </div>
                                                )}
                                          
                                            </div>
                                          </>
                                        )}
                                    </div>
                                  ) :
                                  (
                                    <div
                                      className={`cat-head-sec factCheckDtop ${size.width <= 500
                                          ? "latest-cat-mob"
                                          : categoryName === "factcheck"
                                            ? "latest-cat-dtop"
                                            : ""
                                        }`}
                                    >
                                      <div className="left-side">
                                        {" "}
                                        <h1
                                          className="font-22-ds title large px-3 px-md-0 mt-3 mt-md-0 "
                                          style={{
                                            fontSize:
                                              size?.width > 799
                                                ? "22px !important"
                                                : "initial",
                                          }}
                                        >
                                          {router?.pathname.includes(
                                            "/featured"
                                          ) ? (
                                            <FormattedMessage
                                              id="featured"
                                              defaultMessage="फीचर्ड"
                                            />
                                          ) : router?.pathname.includes(
                                            "/latest"
                                          ) ? (
                                            <div>
                                              {language === "hi"
                                                ? "लेटेस्ट"
                                                : "Latest"}
                                            </div>
                                          ) : (
                                            categoryKeyword
                                          )}
                                        </h1>
                                        {!!subCategoryItem?.length &&
                                          !router?.pathname.includes("/featured") &&
                                          !router?.pathname.includes("/latest") &&
                                          size.width >= 500 && (
                                            <div
                                              style={{
                                                display: "flex",
                                                flexDirection: "row",
                                              }}
                                            >
                                              <div
                                                className={`subcate-sec ${categoryName === "factcheck"
                                                    ? "latest-cat-d"
                                                    : ""
                                                  }`}
                                                style={{
                                                  width: `${categoryName === "padtaal"
                                                      ? "300px"
                                                      : "600px"
                                                    }`,
                                                }}
                                              >
                                                <Swiper
                                                  modules={[Navigation]}
                                                  navigation={{
                                                    prevEl: navprevRef.current,
                                                    nextEl: navnextRef.current,
                                                  }}
                                                  setWrapperSize={[true]}
                                                  onBeforeInit={(swiper) => {
                                                    swiper.params.navigation.prevEl =
                                                      navprevRef.current;
                                                    swiper.params.navigation.nextEl =
                                                      navnextRef.current;
                                                  }}
                                                  breakpoints={{
                                                    0: {
                                                      slidesPerView: 2,
                                                      spaceBetween: 0,
                                                    },
                                                    767: {
                                                      slidesPerView: 2,
                                                      spaceBetween: 0,
                                                    },
                                                    991: {
                                                      slidesPerView: 4,
                                                      spaceBetween: 0,
                                                    },
                                                    1199: {
                                                      slidesPerView: 5,
                                                      spaceBetween: 0,
                                                    },
                                                  }}
                                                >
                                                  <div className="slider-nav">
                                                    <div
                                                      ref={navprevRef}
                                                      className="prev"
                                                    >
                                                      <div className="swiper-circle">
                                                        <i className="icon-arrow-left swiper-icon-arrow"></i>
                                                      </div>
                                                    </div>
                                                    <div
                                                      ref={navnextRef}
                                                      className="next"
                                                    >
                                                      <div className="swiper-circle">
                                                        <i className="icon-arrow-right swiper-icon-arrow"></i>
                                                      </div>
                                                    </div>
                                                  </div>
                                                  {subCategoryItem?.map(
                                                    (subCatItem) =>
                                                      subCatItem?.slug !=
                                                      "naya-taaza" && (
                                                        <SwiperSlide
                                                          className="sub-list"
                                                          key={subCatItem?._id}
                                                        >
                                                          <div
                                                            className={
                                                              subCatItem?.slug ===
                                                                query?.subCategoryName
                                                                ? "active"
                                                                : "inactive"
                                                            }
                                                            key={subCatItem?._id}
                                                          >
                                                            <a
                                                           
  
                                                              href={subCatsHref(
                                                                subCatItem
                                                              )}
                                                              passHref
                                                            >
                                                              <MyLink
                                                                onCustomClick={() => {
                                                                  const payload = {
                                                                    "Category Name":
                                                                      currentCategory?.name,
                                                                    "Category ID":
                                                                      currentCategory?._id,
                                                                    "Sub Category Name":
                                                                      subCatItem?.name,
                                                                  };
                                                                 
                                                                }}
                                                              >
                                                                <span>
                                                                  {language === "hi"
                                                                    ? subCatItem?.nameHn
                                                                    : subCatItem?.name}{" "}
                                                                </span>
                                                              </MyLink>
                                                            </a>
                                                          </div>
                                                        </SwiperSlide>
                                                      )
                                                  )}
                                                </Swiper>
                                              </div>
                                            </div>
                                          )}{" "}
                                      </div>
                                      <div
                                        className="right-side"
                                      // style={{ marginTop: "20px" }}
                                      >
                                        {" "}
                                        <a href="https://ifcncodeofprinciples.poynter.org/profile/tv-today-network-limited">
                                          <a>
                                            {" "}
                                            <img
                                              src="https://static.thelallantop.com/images/post/1713786729251_166a2699-7e1f-a8b0-8dff-d8eaf25ca394.webp"
                                              // height="75px"
                                              className="fact-check-logo"
                                            />
                                          </a>
                                        </a>
                                      </div>
                                    </div>
                                  ))}
  
                              <div
                                className="headline-wrap"
                                key={`${index}_index${categoryKeyword}`}
                              >
                                {size.width > 600
                                  ? oddnaariDesktop(categorizedNews, index)
                                  : oddnaariMobile(categorizedNews, index)}
                              </div>
                            </div>
                          </div>
                          {/* https://vod.thelallantop.com/output-videos-transcoded/vod_07_Dec_2023_ed_bachcha_rai_19/video.mp4 */}
                          {index === 0 &&
                            categorizedNewsArray?.length >= 1 &&
                            router.asPath.includes("election") &&
                            subCategoryName === "lok-sabha-chunav-2024" && (
                              <>
                              
  
                                <div className="electionYatra-outer">
                                  <div className="electionYatra-header">
                                    <div>
                                      <img
                                        src="/assets/images/election-icon.png"
                                        width="35px"
                                        height="35px"
                                        alt="election-icon"
                                      ></img>
                                      <span style={{ marginLeft: "10px" }}>
                                        चुनाव यात्रा
                                      </span>
                                    </div>
                                    <div
                                      className="election-aurDekhe"
                                      onClick={() =>
                                        router.push("/topic/chunav-yatra-2024")
                                      }
                                    >
                                      <span style={{ marginRight: "10px" }}>
                                        और देखें{" "}
                                      </span>
                                      <img
                                        src="/assets/images/arrow.png"
                                        width="10px"
                                        height="10px"
                                      ></img>
                                    </div>
                                  </div>
                                  <div className="electionYatra-columns">
                                    {
                                      <a
                                        className="checkMobileOrNot"
                                        href={`${electionTopicData[0]?.permalink}`}
                                      >
                                        <div className="electionYatra-second election-mobile">
                                          <div
                                            className="electionYatra-second-top"
                                            style={{ position: "relative" }}
                                          >
                                            <img
                                              src={
                                                electionTopicData[0]
                                                  ?.headingImageUrl
                                              }
                                            ></img>
                                            <i
                                              className="icon-play-circle"
                                              width="500px"
                                              height="500px"
                                              style={{
                                                color: "white",
                                                position: "absolute",
                                                left: "2px",
                                                bottom: "-5px",
                                                width: "500px",
                                              }}
                                            ></i>
                                          </div>
                                          <div className="electionYatra-second-bottom">
                                            <h2>{electionTopicData[0]?.title}</h2>
                                            {/* <h4>{electionTopicData[0]?.shortText}</h4> */}
                                          </div>
                                        </div>
                                      </a>
                                    }
  
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "space-between",
                                      }}
                                    >
                                      {electionTopicData
                                        .slice(1)
                                        .map((x, index) => {
                                          return (
                                            <a
                                              href={`${x?.permalink}`}
                                              key={index}
                                            >
                                              <div className="electionYatra-first">
                                                <div className="electionYatra-first-div">
                                                  <div
                                                    style={{
                                                      // flex: "0 0 38%",
                                                      position: "relative",
                                                      justifyContent: "center",
                                                      alignContent: "center",
                                                      alignItems: "center",
                                                    }}
                                                  >
                                                    <img
                                                      src={x?.headingImageUrl}
                                                      width="160px"
                                                      height="90px"
                                                    ></img>
                                                    <i
                                                      className="icon-play-circle"
                                                      style={{
                                                        color: "white",
                                                        position: "absolute",
                                                        left: "2px",
                                                        bottom: "-5px",
                                                      }}
                                                    ></i>
                                                  </div>
                                                  <div
                                                    className="electionYatra-first-div-right"
                                                    style={{ flex: "0 0 60%" }}
                                                  >
                                                    <div>
                                                      <div
                                                        style={{
                                                          overflow: "hidden",
                                                        }}
                                                      >
                                                        <div
                                                          style={{
                                                            display:
                                                              "-webkit-box",
                                                            lineHeight: "22px",
                                                            WebkitLineClamp: "3",
                                                            WebkitBoxOrient:
                                                              "vertical",
                                                            marginTop: "5px",
                                                          }}
                                                          className="chunav-yatra-small-title"
                                                        >
                                                          {x?.title}
                                                        </div>
                                                      </div>
                                                    </div>
                                                    <div
                                                      style={{
                                                        display: "flex",
                                                        gap: "1rem",
                                                      }}
                                                    >
                                                
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </a>
                                          );
                                        })}
                                    </div>
  
                                    {
                                      <a
                                        className="checkDesktopOrNot"
                                        style={{ width: "100%" }}
                                        href={`${electionTopicData[0]?.permalink}`}
                                      >
                                        <div className="electionYatra-second election-desktop">
                                          <div
                                            className="electionYatra-second-top"
                                            style={{ position: "relative" }}
                                          >
                                            <img
                                              src={
                                                electionTopicData[0]
                                                  ?.headingImageUrl
                                              }
                                            ></img>
                                            <i
                                              className="icon-play-circle"
                                              width="500px"
                                              height="500px"
                                              style={{
                                                color: "white",
                                                position: "absolute",
                                                left: "2px",
                                                bottom: "-5px",
                                                width: "500px",
                                              }}
                                            ></i>
                                          </div>
                                          <div className="electionYatra-second-bottom">
                                            <h2>{electionTopicData[0]?.title}</h2>
                                            {/* <h4>{electionTopicData[0]?.shortText}</h4> */}
                                          </div>
                                        </div>
                                      </a>
                                    }
                                  </div>
                                </div>
                               
                              </>
                            )}
                          {index === 0 && (
                            <Container align="center">
                              <div className="container-row py-2" align="center">
                                <p
                                  style={{
                                    fontSize: "11px",
                                    color: "#b7b7b7",
                                    textAlign: "center",
                                    marginBottom: "0px",
                                  }}
                                >
                                  Advertisement
                                </p>
                                <div
                                  id={
                                    size.width > 600
                                      ? "div-gpt-ad-1701945220410-1"
                                      : "div-gpt-ad-1701946028239-1"
                                  }
                                  style={{
                                    minWidth: "300px",
                                    minHeight: "100px",
                                    display: "block !important",
                                    alignContent: "center",
                                    textAlign: "-webkit-center",
                                  }}
                                >
                                  <script
                                    type="text/javascript"
                                    dangerouslySetInnerHTML={{
                                      __html:
                                        size.width > 600
                                          ? `{googletag.cmd.push(function() { googletag.display('div-gpt-ad-1701945220410-1')})`
                                          : `{googletag.cmd.push(function() { googletag.display('div-gpt-ad-1701946028239-1')})`,
                                    }}
                                  ></script>
                                </div>
                              </div>{" "}
                            </Container>
                          )}
  
                          {index === 1 && categorizedNewsArray?.length >= 1 && (
                            <Container align="center">
                             
                              <div className="container-row py-2" align="center">
                                <p
                                  style={{
                                    fontSize: "11px",
                                    color: "#b7b7b7",
                                    textAlign: "center",
                                    marginBottom: "0px",
                                  }}
                                >
                                  Advertisement
                                </p>
                                <div
                                  id={
                                    size.width > 600
                                      ? "div-gpt-ad-1701945220410-2"
                                      : "div-gpt-ad-1701946028239-2"
                                  }
                                  style={
                                    size.width > 600
                                      ? {
                                        minWidth: "300px",
                                        minHeight: "100px",
                                        display: "block !important",
                                        alignContent: "center",
                                        textAlign: "-webkit-center",
                                      }
                                      : {
                                        minWidth: "300px",
                                        minHeight: "260px",
                                        display: "block !important",
                                        alignContent: "center",
                                        textAlign: "-webkit-center",
                                      }
                                  }
                                >
                                  <script
                                    type="text/javascript"
                                    dangerouslySetInnerHTML={{
                                      __html:
                                        size.width > 600
                                          ? `{googletag.cmd.push(function() { googletag.display('div-gpt-ad-1701945220410-2')})`
                                          : `{googletag.cmd.push(function() { googletag.display('div-gpt-ad-1701946028239-2')})`,
                                    }}
                                  ></script>
                                </div>
                              </div>{" "}
                            </Container>
                          )}
  
                          {adPlacement970_250 &&
                            !!Object.keys(adPlacement970_250[index] || {})
                              ?.length && (
                              <div className="sponser-card no-bg s-970x250">
                                <a
                                  href={
                                    (adPlacement970_250 &&
                                      adPlacement970_250[index]?.redirect_url) ||
                                    "#"
                                  }
                                >
                                  <img
                                    src={
                                      adPlacement970_250 &&
                                      adPlacement970_250[index]?.image_url
                                    }
                                    alt="ads"
                                  />
                                </a>
                              </div>
                            )}
                        </Fragment>
                      </div>
                    </Container>
                  ) : (
                    <NotFound />
                  )}
                </div>
              );
            })}
          </InfiniteScroll>
        )}
  
        {showMetaTitleFromApi && showMetaDescriptionFromApi && showMetaKeywordFromApi && <SeoScripts showMetaTitleFromApi={showMetaTitleFromApi} showMetaDescriptionFromApi={showMetaDescriptionFromApi} showhowMetaKeywordFromApi = {showMetaDescriptionFromApi} categoryName={categoryName} seoData={seoData} newsData={newsData}/>}
        <FAQComponent data={faqData} />
  
        <Container align="center"></Container>
        <div id="taboola-below-category-thumbnails"></div>
      </>
    );
  };
  export default Health;
  