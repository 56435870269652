import React, {
  // useCallback,
  // useEffect, useState
} from "react";
// import Link from "next/link";
//import OptionDropdown from "components/category/about/optionDropdown/optionDropdown";
// import {
//   // useDispatch,
//   useSelector
// } from "react-redux";
// import { postLike } from "redux/actions/postAction";
// import { useSession } from "next-auth/react";
// import CustomPopup from "components/shared/modal/customPopup";
// import { getLanguage } from "localization/i8intl";
import { useRouter } from "next/router";
import { timeSince } from "constants/cardsConstant";
// import { formatDateEnglishOnly } from "constants/cardsConstant";
// import styled from "styled-components";
// import { Dropdown } from "react-bootstrap";
// import {
//   FacebookIcon,
//   FacebookShareButton,
//   WhatsappIcon,
//   WhatsappShareButton,
//   TwitterShareButton,
//   TwitterIcon,
//   LinkedinIcon,
//   LinkedinShareButton,
//   EmailIcon,
//   EmailShareButton,
// } from "next-share";
// // import uiHelper from "utils/uiHelper";
// // import { saveBookmark } from "redux/actions/bookmarksActions";
// import { Modal } from "react-bootstrap";
// import { timeSince } from "constants/cardsConstant";
// import ClevertapReact from "utils/clevertapHelper";
import { MyLink } from "api/cleverTap";
import Image from "next/image";
import { useWindowSize } from "api/commonConstants";

// import Image from "next/image";
// import ViewSource from "components/shared/viewSource/view-source";
// import { toBase64, shimmer } from "constants/cardsConstant";
// import { type } from "os";
// const DropdownDiv = styled.div`
//   display: flex;
//   flex-direction: row;
//   justify-content: space-around;
//   position: relative;
//   span:first-child {
//     @media (max-width: 768px) {
//       display: none;
//     }
//   }
//   .option-dropdown {
//     display: flex;
//     align-items: center;
//   }
//   .dropdownList {
//     position: absolute;
//     background-color: #fff;
//     top: 26px;
//     right: 0px;
//     z-index: 4;
//     padding: 8px;
//     box-shadow: 0px 0px 8px 0px #0000003d;
//     i {
//       margin-right: 5px;
//     }
//   }
//   ul {
//     list-style: none;
//     padding: 0;
//     margin: 0;
//     li {
//       font-weight: 700;
//       cursor: pointer;
//     }
//   }
// `;

const ReadNews = ({
  postData = {},
  postType = "",
  isBanner,
  isSearch = false,
  isTopic = false,
  trending = false,
}) => {
  // if (isBanner) {
  //   console.log(isBanner);
  // }
  //console.log("bookmarkPostData",postData)
  //const sessionData = JSON.parse(localStorage.getItem("lallantopSession2"));

  const size = useWindowSize();
  const router = useRouter();
  // const { query } = router;
  // const { userLocalDetail } = useSelector(({ AuthReducer }) => AuthReducer);
  // const { data: session } = useSession();
  // const [loginPrompt, setLoginPrompt] = useState(false);
  // const [videoDuration, setVideoDuration] = useState(0);
  // const [postStateData, setPostStateData] = useState([]);
  // const { postDetails } = useSelector(({ PostReducer }) => PostReducer);
  // const [liked, setLiked] = useState(postData?.isLiked);
  // const [openShareModal, setOpenShareModal] = useState(false);
  // const [shareData, setShareData] = useState({});
  // const [countLike, setCountLike] = useState(postData?.likeCount);
  // const [showMoreCategoryDropdown, setShowMoreCategoryDropdown] =
  //   useState(true);
  // const dispatch = useDispatch();
  // const countFormat = (count) => {
  //   return count > 1000 ? (count / 1000).toFixed(1).concat("k") : count;
  // };
  // const pageToOpen = `/${(
  //   postStateData?.categoryName || query?.categoryName
  // )?.toLowerCase()}/postdetail`;
  // const pageToOpen =
  // router.pathname === "/show/detail/[seriesSlug]"
  //   ? `/show/detail/${query?.seriesSlug}/${postStateData?.postSlug}`
  //   : postStateData?.type === "audio"
  //   ? postStateData?.permalink
  //   : // `/audio/detail/${postStateData?.seriesSlug}/${postStateData?.postSlug}`
  //     `/${
  //       postStateData?.categorySlug?.toLowerCase() || query?.categoryName
  //     }/post/${postStateData?.postSlug || ""}`;
  // const pageToOpen = postData && postData?.permalink?.split(".com")[1];
  var pageToOpen = postData && postData?.permalink?.split(".com")[1];
  if (trending === true) {
    pageToOpen += `?utm_source=Internal_LT&utm_medium=${postData?.type}&utm_name=trending`;
  }
  // const [language, setLanguage] = useState("");
  // useEffect(() => {
  //   setLanguage(getLanguage());
  // }, []);
  // const handleLike = useCallback(
  //   (postObj, postDetails) => {
  //     if (userLocalDetail?.id || session?.user?.email) {
  //       dispatch(postLike(postObj, postType, postDetails));
  //     } else {
  //       setLoginPrompt(true);
  //     }
  //   },
  //   [dispatch, session?.user?.email, postType, userLocalDetail?.id]
  // );

  // useEffect(() => {
  //   if (
  //     postData?.postId
  //       ? postDetails?._id === postData?.postId
  //       : postDetails?._id === postData?._id
  //   ) {
  //     setPostStateData(postDetails);
  //     // setLiked(postDetails?.isLiked);
  //     // setCountLike(postDetails?.likeCount);
  //   }
  // }, [postDetails, postData?._id, postData?.postId]);

  // useEffect(() => {
  //   setPostStateData(postData);
  // }, [postData]);

  // useEffect(() => {
  //   var au = document?.createElement("audio");
  //   au.src = postStateData?.assetFileLink;
  //   function handler() {
  //     var duration = au?.duration;
  //     setVideoDuration(new Date(duration * 1000).toISOString().substr(14, 5));
  //   }
  //   au.addEventListener("loadedmetadata", handler);
  //   return () => {
  //     au.removeEventListener("loadedmetadata", handler);
  //   };
  // }, [postStateData]);

  // const handleShare = () => {
  //   const share = {
  //     title: postData?.title,
  //     text: postData?.title,
  //   };
  //   //if (shareUrl) share.url = shareUrl;

  //   share.url = postData?.permalink;

  //   //console.log("share: ", share);
  //   setShareData(share);
  //   if (!navigator || !navigator.share) {
  //     setOpenShareModal(true);
  //     console.log("Platform not supported!");
  //   } else {
  //     uiHelper.share(share);
  //   }
  // };

  // const handleBookmark = (_id, type) => {
  //   if (userLocalDetail?.id || session?.user?.email) {
  //     const postObj = {
  //       table: "post",
  //       postId: _id,
  //       type: type,
  //     };
  //     dispatch(saveBookmark(postObj));
  //     //setShowDropdown((e) => !e);
  //   } else {
  //     if (setLoginPrompt) setLoginPrompt(true);
  //   }
  // };
  // const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  //   <a
  //     href=""
  //     ref={ref}
  //     onClick={(e) => {
  //       e.preventDefault();
  //       onClick(e);
  //     }}
  //   >
  //     &#x25bc;
  //     {children}
  //   </a>
  // ));

  // useEffect(() => {
  //   var au = document?.createElement("audio");
  //   au.src = postStateData?.assetFileLink;
  //   function handler() {
  //     var duration = au?.duration;
  //     setVideoDuration(new Date(duration * 1000).toISOString().substr(14, 5));
  //   }
  //   au.addEventListener("loadedmetadata", handler);
  //   return () => {
  //     au.removeEventListener("loadedmetadata", handler);
  //   };
  // }, [postStateData]);

  const isHomePath = router.asPath === "/";

  return (
    <>
      <div
        className="headline-box-sm"
      // style={{
      //   border: "none !important",
      //   padding: "0px !important",
      //   // marginTop: "8px",
      // }}
      >
        <div
          className={
            router?.pathname?.includes("show")
              ? "headline-left-col show-screen"
              : "headline-left-col"
          }
        >
          <div
            className="headline-pic-box"
            style={{ minHeight: "0% !important" }}
          >
            {postData?.type === "liveblog" &&
              postData?.liveBlogData?.eventStatus === "live" && (
                <img
                  src="/assets/images/giphy.gif"
                  style={{
                    position: "absolute",
                    width: "50px",
                    zIndex: "99",
                    height: "60px",
                    right: "0",
                    top: "-12px",
                  }}
                  alt="live"
                ></img>
              )}
            <a className="cursor-pointer" href={pageToOpen}>
              <MyLink
                // href={pageToOpen}
                className="headline-thumb"
                onCustomClick={() => {
                  if (isHomePath) {
                    // ClevertapReact.event(
                    //   "homepage_card",
                    //   createHomeCardPayload("homepage_card", postStateData)
                    // );
                  }
                }}
              >
                {/* {postStateData?.headingImageUrl && (
                  <>
                    <ViewSource pathname="pages/shimmer.tsx" />
                    <Image
                      alt={postStateData?.imgAltText || "News pic"}
                      src={postStateData?.headingImageUrl}
                      // placeholder="blur"
                      // blurDataURL={`data:image/svg+xml;base64,${toBase64(shimmer(700, 475))}`}
                      width={700}
                      height={475}
                    />
                  </>
                )} */}
                {/* <img
                  src={postStateData?.headingImageUrl}
                  alt={postStateData?.imgAltText || "News pic"}
                  height="84%"
                  width="100%"
                  style={{
                    // height: "84%",
                    // objectFit: "cover",
                    // width: "100% !important",
                    minHeight: "0% !important",
                    borderRadius: "0.5rem",
                  }}
                  className={"small-card"}
                  loading='lazy'
                /> */}
                {postData && postData?.headingImageUrl && (
                  <>
                    <Image
                      src={postData?.headingImageUrl + "?width=200"}
                      layout="fill"
                      alt={postData?.imgAltText || "News pic"}
                      className={"small-card"}
                      loading="lazy"
                      sizes="(max-width: 1500px) 150vw,
                (max-width: 768px) 70vw,
                (max-width: 500px) 40vw,
                33vw
               "
                    />
                    <div className="news-type-icon">
                      <i
                        style={{ color: "white" }}
                        className={`icon-${postData?.type === "text" ||
                          postData?.type === "liveblog"
                          ? "cat-read"
                          : postData?.type === "audio"
                            ? "mic"
                            : "play-circle"
                          }`}
                      ></i>
                    </div>
                  </>
                )}
              </MyLink>
              {/* <a >
                <img
                  src={postStateData?.headingImageUrl}
                  alt={postStateData?.imgAltText || "News pic"}
                />
                <p>{postStateData?.imgCaption}</p>
              </a> */}
            </a>
            {/* <div className="news-type-box">
              <i
                className={`icon-${
                  postStateData?.type === "text"
                    ? "cat-read"
                    : postStateData?.type === "audio"
                    ? "mic"
                    : "play-circle"
                }`}
              ></i>
              {postStateData?.type === "audio" && (
                <span>
                  {postStateData?.type === "audio" && (
                    <span>{videoDuration}</span>
                  )}
                </span>
              )}
            </div> */}
          </div>
        </div>
        <div
          className="headline-right-col"
          style={{ position: "relative", paddingTop: "5px" }}
        >
          <div className="headline-body">
            <div className="headline-info">
              <div className="series-row">
                {/* <label>
                  {language === "hi"
                    ? postStateData?.subCategoryNameHn
                    : postStateData?.subCategoryName}
                </label> */}
                {/* {postStateData?.title && (
                  <>
                    <label>{postStateData?.title}</label>
                    <span>|</span>  
                  </>
                )} */}

                {/* <ul className="hashtags-list">
                  {postStateData?.hashtags?.map((hashTag, index) => (
                    <li key={index}>
                      <a
                        href={{
                          pathname: "/hashtagDetail",
                          query: { tagname: hashTag ,contentType: "video"},
                        }}
                      >
                        <a>{hashTag}</a>
                      </a>
                    </li>
                  ))}
                </ul> */}
              </div>
              <h3
                style={{
                  fontFamily:
                    "Mukta,system-ui, -apple-system, BlinkMacSystemFont",
                  fontStyle: "normal",
                  fontWeight: "500 !important",
                  fontSize: "17px !important",
                  lineHeight: "130%",
                  paddingRight: "10px",
                }}
              >
                <a className="cursor-pointer" href={pageToOpen}>
                  {" "}
                  {postData?.title?.padEnd(50)}
                </a>
              </h3>
            </div>
            {/* <div className="more-col">
              <OptionDropdown
                postData={postStateData}
                showLoginPrompt={() => setLoginPrompt(true)}
              />
            </div> */}
          </div>
          {/* {isSearch && (
            <div className="published-date-display">
              <div className="published-date">
                {" "}
                {formatDateEnglishOnly(postData?.publishedAt).date +
                  ", " +
                  formatDateEnglishOnly(postData?.publishedAt).time}
              </div>
            </div>
          )} */}
          {/* {console.log("isSearchisSearch", isSearch)} */}
          {(isTopic || isSearch) && (
            <div style={{ color: "gray", marginBottom: "2px" }}>
              <span className="pre-span" style={{ marginRight: "100px" }}>
                {postData?.publishedAt
                  ? timeSince(postData?.publishedAt)
                  : timeSince(postData?.createdAt)}{" "}
                ago
              </span>
            </div>
          )}
          {/* <div className="three-dot-headline-menu">
            <img
              className="arrow-down dots"
              src="/assets/images/moreMenuVertical.png"
              width="20"
              height="20"
              alt=""
            />
          </div> */}

          {/* <ul className="headline-footer1" style={{
            display: "flex",
            justifyContent: "space-between",
            listStyle: "none",
            padding: "0px"
          }}>
            <li>
                <div className="news-type-box" style={{padding: "0px !important"}}>
              <i
                className={`icon-${
                  postStateData?.type === "text"
                    ? "cat-read"
                    : postStateData?.type === "audio"
                    ? "mic"
                    : "play-circle"
                }`}
              ></i>
              
            </div>
            </li>
            <li>
              <div>
                <span className="pre-span" style={{marginRight: "100px"}}>
                  {postData?.publishedAt
                    ? timeSince(postData?.publishedAt)
                    : timeSince(postData?.createdAt)}{" "}
                  ago
                </span>
              </div>
            </li>
            {/* <li>
              <a
                href={{
                  pathname: `${pageToOpen}`,
                  // query: {
                  //   postSlug: postStateData?.postSlug,
                  //   postId: postData?._id,
                  // },
                  hash: "comment",
                }}
                // scroll={true}
                passHref
              >
                <a>
                  <i className="icon-chat-dots"></i>
                  <span>
                    {postStateData?.commentCount !== 0
                      ? countFormat(postStateData?.commentCount)
                      : ""}
                  </span>
                </a>
              </a>
            </li> */}
          {/* <li>
              <a>
                <i className="icon-eye"></i>
                <span
                // comment style
                style={
                  router?.pathname.includes("show")
                    ? { display: "none" }
                    : { display: "block" }
                }
                >
                  {countFormat(postStateData?.viewCount)}
                  {postStateData?.viewCount !== 0
                    ? countFormat(postStateData?.viewCount)
                    : ""}
                </span>
              </a>
            </li> */}
          {/* <li className="like-item">
              <div>
                <i
                  className={`icon-bookmark${
                    postStateData?.isBookmarked ? "-fill px-1" : " px-1"
                  }`}
                  role={"button"}
                  aria-label="Bookmark Toggle"
                  style={{ fontWeight: "600" }}
                  onClick={() => {
                    handleBookmark(
                      postData?.postId || postData?._id,
                      postData?.type
                    );
                    if (!postStateData?.isBookmarked) {
                      ClevertapReact.event(
                        "card_bookmark",
                        createBookmarkPayload("card_bookmark", postStateData)
                      );
                    }
                  }}
                ></i>
              </div>

              <div>
                <i
                  className={`icon-share px-1`}
                  role={"button"}
                  aria-label="Share Button"
                  style={{ fontWeight: "600" }}
                  onClick={() => {
                    ClevertapReact.event(
                      "card_share",
                      createCardPayload("card_share", postStateData)
                    );
                    handleShare();
                  }}
                ></i>
              </div>
              <div>
                <i
                  onClick={() => {
                    ClevertapReact.event(
                      postStateData?.isLiked ? "card_unlike" : "card_like",
                      createCardPayload("card_like", postStateData)
                    );
                    postType === "column"
                      ? handleLike(
                          { columnId: postStateData?._id },
                          postStateData
                        )
                      : handleLike(
                          postStateData?.postId
                            ? { _id: postStateData?.postId }
                            : { _id: postStateData?._id },
                          postStateData
                        );
                  }}
                  style={{ fontWeight: "500" }}
                  className={`icon-heart${
                    liked || postStateData?.isLiked ? "-fill px-1" : " px-1"
                  }`}
                ></i>
                {/* <span>{countLike !== 0 ? countFormat(countLike) : ""}</span> */}
          {/* </div>
            </li> 
          </ul> 
          */}
        </div>
        {/* <CustomPopup
          showModal={loginPrompt}
          onClose={() => setLoginPrompt(false)}
        /> */}
        {/* <Modal
          show={openShareModal}
          fullscreen={false}
          centered
          size="sm"
          onHide={() => setOpenShareModal(false)}
        >
          <div className="row p-2">
            <div className="col-6">Social Share</div>
            <div className="col-6 text-end">
              <div role={"button"} onClick={() => setOpenShareModal(false)}>
                <span className="icon-close"></span>
              </div>
            </div>
          </div>

          <Modal.Body>
            <div align="center">
              <FacebookShareButton quote={shareData.text} url={shareData.url}>
                <FacebookIcon className="me-1" size={32} round />
              </FacebookShareButton>

              <TwitterShareButton title={shareData.text} url={shareData.url}>
                <TwitterIcon className="me-1" size={32} round />
              </TwitterShareButton>

              <EmailShareButton
                url={shareData.url}
                subject={shareData.text}
                body={shareData.url}
              >
                <EmailIcon className="me-1" size={32} round />
              </EmailShareButton>

              <LinkedinShareButton title={shareData.text} url={shareData.url}>
                <LinkedinIcon className="me-1" size={32} round />
              </LinkedinShareButton>
              <WhatsappShareButton url={shareData.url} title={shareData.text}>
                <WhatsappIcon className="me-1" size={32} round />
              </WhatsappShareButton>
              <hr />
              <span style={{ fontSize: "13px" }} className="text-dark">
                <span id="cpy">Copy</span> to Clipboard{" "}
                <i className="las la-clipboard la-lg"></i>
              </span>
              <input
                style={{ fontSize: "13px" }}
                onClick={() => {
                  let et = document.getElementById("copy");
                  et.select();
                  document.execCommand("copy");
                  document.getElementById("cpy").textContent = "Copied";
                }}
                id="copy"
                value={shareData.url}
                type="text"
                readOnly
                className="form-control"
              />
            </div>
          </Modal.Body>
        </Modal> */}
      </div>
      {size.width < 500 && (
        <div
          className="line-newsImageTextBanner1 line-readNews"
          style={{
            height: "1px",
            background: "rgba(166, 166, 166, 0.2)",
            width: "100%",
          }}
        ></div>
      )}
    </>
  );
};
export default ReadNews;
