// import { timeSince } from "constants/cardsConstant";
// import { timeSince } from "constants/cardsConstant";
import React from "react";

const NewsWidget = ({ newsData }) => {


  const handleNewsClick = (item) => {
    // const obj = {
    //   eventName : "News_Card_Click",
    //   pageTitle : "Home Page"
    // }
    // callGaEvent(obj)
    let type = item?.type == 'video' ? 'video' : 'post'

    window.location.href = `/sports/${type}/${item?.postSlug}`
  }

  return (
    <div

      onClick={() => {
        handleNewsClick(newsData[0])
      }}

      style={{
        background: "#FFFFFF1A",
        borderRadius: "12px",
        padding: "16px",
        display: "flex",
        alignItems: "center",
        // width: "600px",
        gap: "16px",
        // boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
        border: "1px solid #FFFFFF4D",

      }}
    >
      {/* Image Section */}
      <div style={{ flexShrink: 0 }}>
        <img
          src={newsData[0]?.headingImageUrl}
          // src = ""
          alt="Pakistan Cricket"
          style={{
            width: "120px",
            height: "80px",
            borderRadius: "8px",
            objectFit: "cover",
          }}
        />
      </div>

      {/* Text Content */}
      <div style={{ flex: 1, color: "white" }}>
        <p style={{ margin: 0, fontSize: "18px", fontWeight: "normal" }}>

          {newsData[0]?.title}
        </p>
        {/* <div
          style={{
            display: "flex",
            alignItems: "center",
            marginTop: "8px",
            opacity: 0.8,
          }}
        >
          {/* <img
            src="clock-icon.png"
            alt="Clock Icon"
            style={{ width: "16px", height: "16px", marginRight: "6px" }}
          /> */}

        {/* <span style={{ fontSize: "14px" }}>
            <i
              className={newsData[0]?.type == 'video' ? `icon-play-circle` : 'icon-cat-read'}
              width="500px"
              height="500px"
              style={{
                color: "white",
                // position: "absolute",
                // marginRight :"10px"
                paddingRight: "0px"
                // left: "2px",
                // bottom: "-5px",
                // width: "500px",
              }}
            ></i> {newsData[0]?.publishedAt
              ? timeSince(newsData[0]?.publishedAt)
              : timeSince(
                newsData[0]?.createdAt
              )}{" "}
            ago</span> */}
      </div>
    </div >
  );
};

export default NewsWidget;
