import React from "react";
// import { useRouter } from "next/router";

function Strip({ size, showActive }) {
  const list = [
    {
      displayText: "All",
      url: "/sports/cricket",
      keyToShow: "",
      id: "all"
    },
    {
      displayText: "IPL",
      url: "/sports/ipl",
      keyToShow: "",
      id: "ipl"
    },
    {
      displayText: "Champions Trophy",
      url: "/sports/cricket/champions-trophy",
      keyToShow: "",
      id: "champions-trophy"
    },
  ];


  return (
    <>
      <div className="d-flex justify-content-center align-items-center mb-5 mb-lg-0">
        <div
          className="d-flex justify-content-center justify-content-sm-between bg-white align-items-center rounded-4 px-5 py-3 flex-wrap"
          style={{ width: "760px" }}
        >
          <div style={{ width: "130px", height: "58.21px" }} className="d-flex justify-content-center align-items-center">
            <img src="/assets/images/cric-strip.svg" className="w-100 h-100" alt="cric-strip" />
          </div>
          <div className="d-flex align-items-center gap-4 scroll-container" style={{ overflowX: "auto",  whiteSpace: "nowrap" }}>
            {list.map((item, index) => (
              <div
                key={index}
                className="active-strip rounded-4 p-4 py-2"
                style={{
                  backgroundColor: showActive === item.id ? "#d9368b" : "#DFDFDF",
                  flex: "0 0 auto",
                }}
              >
                <a href={item.url} className={`active-strip ${showActive === item.id ? "text-white" : "text-dark"}`}>
                  <span>{item.displayText}</span>
                </a>
              </div>
            ))}
          </div>

        </div>
      </div>

      <style jsx>{`
     .active-strip:hover {
    background-color: #d9368b !important;
    cursor:pointer;
  }

  .active-strip:hover a {
    color: white !important;
    cursor: pointer;
  }
      `}</style>
    </>
  );
}

export default Strip;
