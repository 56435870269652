import React, { useEffect, useState } from "react";
// import getConfig from "next/config";
import {
  fetchAndActivate,
  getRemoteConfig,
  getString,
} from "firebase/remote-config";
import app from "utils/firebase";

const ElectionsWidget = () => {
  const [publicRuntimeConfig ,setPublicRunTimeConfig] = useState({
    electionExitPollToggle: false,
    electionResultToggle: true,
    vidhansabhaResultToggle: true,
    resultVersion: 1.6,
    exitPollVersion: 1.2,
    electionYear: 2024,
    isElectionAlpha: true,
    keyCandidate: true,
  })
  // const { publicRuntimeConfig } = getConfig();
  const [lokExit,setLokExit] = useState(true)
  useEffect(()=>{
    if (typeof window !== "undefined") {
      const remoteConfig = getRemoteConfig(app);
      remoteConfig.settings.minimumFetchIntervalMillis = 3600000;

      fetchAndActivate(remoteConfig)
        .then(() => {
          const titleData = getString(remoteConfig, "electionKeys");
          const parsedData = JSON.parse(titleData)
          setPublicRunTimeConfig(parsedData)
          // console.log("Election Keys are ", (parsedData))
          // setVideoUrl(titleData);
        })
        .catch((err) => {
          console.error(err)
        });
    }
  },[])
  // console.log(
  //   "ElectionWidges are",
  //   publicRuntimeConfig?.electionExitPollToggle
  // );
  return (
    <>
      {publicRuntimeConfig?.electionExitPollToggle === true && (
        <div className="election-result-tally">
          <h2 style={{paddingLeft:"10px",fontWeight:"700",fontSize:"22px", paddingTop:"10px",paddingBottom:"5px",background:"white"}}>एग्जिट पोल</h2>
          {/* <div class="election-schedule-header homepage-iframe-election" >
            <div class={`tab_items ${lokExit ? "active" : ""}`} onClick={()=>setLokExit(true)}>लोकसभा</div>
            <div class={`tab_items ${!lokExit ? "active" : ""}`} onClick={()=>setLokExit(false)}>
                विधान सभा
            </div>
          </div> */}
          { lokExit ? <iframe
            width="100%"
            height="370px"
            scrolling="no"
            // src= "https://electionresults.intoday.in/elections/2024/loksabha-elections/html/exit-poll/lok-sabha-exit-poll.html?site=lallantop&theme=1&v=1.0"
            src="https://electionresults.intoday.in/elections/2024/loksabha-elections/html/loksabhastate-exitpoll/lok-sabha-exit-poll.html?site=lallantop&theme=1&v=5.0"
          ></iframe> : <iframe
          width="100%"
          height="340px"
          scrolling="no"
          src= "https://electionresults.intoday.in/elections/2024/assembly-elections/html/exit-poll/assembly-exit-poll.html?site=lallantop&device=web&v=5.1"
        ></iframe>  }
        </div>
      )}
      {/* {publicRuntimeConfig?.electionResultToggle === true && (
        <div
          className="election-result-tally-2"
        >
                    <h2 style={{paddingLeft:"10px",fontWeight:"700",fontSize:"22px", paddingTop:"10px",paddingBottom:"5px",background:"white"}}>लाइव लोक सभा चुनाव परिणाम</h2>
          <iframe
            width="100%"
            height="500px"
            scrolling="no"
            src="https://electionresults.intoday.in/elections/2024/loksabha-elections/html/loksabha-result-tally-2024.html?site=lallantop&device=web&v=4.5"
          ></iframe>
        </div>
      )} */}
         {/* {publicRuntimeConfig?.vidhansabhaResultToggle && (
          <div className="vidhan-result-iframe">
            <h2 style={{paddingLeft:"10px",fontWeight:"700",fontSize:"22px", paddingTop:"10px",paddingBottom:"5px",background:"white"}}>लाइव विधान सभा चुनाव परिणाम</h2>
            <div>
              <iframe
                src="https://electionresults.intoday.in/elections/2024/assembly-elections/html/assembly-result-tally-2024.html?site=lallantop&device=web&v=1.6&state_name=odisha"
                width="100%"
                height="400px"
              ></iframe>
            </div>
          </div>
        )} */}
    </>
  );
};

export default ElectionsWidget;
